import React, { useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';

import { useLazyQuery } from '@apollo/client';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DragAndDropUploadImageComponent from '../../components/CompDragDropInfocardUpload';
import {
    GetArtworksOrMakersDocument, GetArtworksOrMakersQuery, GetArtworksOrMakersQueryVariables, OaiArtwork, OaiMaker, OcrArtwork
} from '../../graphql/server-graphql-schema';
import CompSearchBar from './CompSearchBar';
import DynamicRenderer from './DynamicRenderer';

const SearchPage: React.FC = () => {
  const [results, setResults] = useState<GetArtworksOrMakersQuery['getArtworksOrMakers'] | null>(null);
  const [resetTrigger, setResetTrigger] = useState(false);
  const [showImageSearch, setShowImageSearch] = useState(false); // Toggle for DragAndDropUploadImageComponent
  const [fetchResults, { loading, error }] = useLazyQuery<GetArtworksOrMakersQuery, GetArtworksOrMakersQueryVariables>(
    GetArtworksOrMakersDocument,
    {
      onCompleted: (data) => {
        setResults(data.getArtworksOrMakers);
      },
      onError: () => {
        setResults(null);
      },
    },
  );

  const triggerReset = () => setResetTrigger((prev) => !prev);
  const isSmallScreen = useMediaQuery({ query: '(max-width: 768px)' });

  const handleSearch = async (artworkInfo: OcrArtwork | string) => {
    if (!artworkInfo) return;

    triggerReset();

    setResults(null); // Clear previous results
    try {
      await fetchResults({
        variables: {
          input: { search: typeof artworkInfo === 'string' ? artworkInfo : JSON.stringify(artworkInfo) },
        },
      });
    } catch (err) {
      console.error('Error fetching results:', err);
    }
  };

  return (
    <Container
      className="mt-5 "
      style={{
        minHeight: results ? 'auto' : '70vh', // Ensure full height before search
        paddingBottom: results ? '300px' : '500px', // Adjust bottom padding dynamically
      }}
    >
      <Row className="justify-content-center">
        <Col sm={12} md={9}>
          {!results && <h1 className="text-center mb-4">Search Artworks or Artists</h1>}

          {isSmallScreen ? (
            <div className="d-flex flex-column align-items-center mb-3">
              {!showImageSearch && (
                <div className="w-100 mb-3">
                  <CompSearchBar loading={loading} onSearch={handleSearch} />
                </div>
              )}
              <Button
                style={{ marginTop: 20, width: 150 }}
                variant="primary"
                className="d-flex align-items-center justify-content-center"
                onClick={() => setShowImageSearch(!showImageSearch)}
              >
                <FontAwesomeIcon icon={faSearch} className="me-2" />
                {showImageSearch ? 'Text' : 'Image'} Search
              </Button>
            </div>
          ) : (
            <div className="d-flex justify-content-center align-items-center mb-3">
              {!showImageSearch && <CompSearchBar loading={loading} onSearch={handleSearch} />}
              <Button
                style={{ width: 200 }}
                variant="primary"
                className="ms-3 d-flex align-items-center justify-content-center"
                onClick={() => setShowImageSearch(!showImageSearch)}
              >
                <FontAwesomeIcon icon={faSearch} className="me-2" />
                {showImageSearch ? 'Text' : 'Image'} Search
              </Button>
            </div>
          )}

          {error && <div className="text-danger text-center">Error: {error.message}</div>}
        </Col>
      </Row>

      {showImageSearch && ( // Conditionally render DragAndDropUploadImageComponent
        <Row>
          <Col>
            <DragAndDropUploadImageComponent resetTrigger={resetTrigger} />
          </Col>
        </Row>
      )}
      {results && (
        <Row className="justify-content-center mt-4">
          <Col md={10}>
            {results.artworks?.length > 0 && (
              <div className="mb-4">
                {results.artworks.map((artwork: OaiArtwork, index: number) => (
                  <DynamicRenderer key={index + 'a'} type="artwork" schema={artwork.schema} data={artwork} />
                ))}
              </div>
            )}
            {results.makers?.length > 0 && (
              <div className="mb-4">
                {results.makers.map((maker: OaiMaker, index: number) => (
                  <DynamicRenderer key={index + 'm'} type="maker" schema={maker.schema} data={maker} />
                ))}
              </div>
            )}
            {results.artworks?.length === 0 && results.makers?.length === 0 && (
              <span className="text-center">No results found.</span>
            )}
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default SearchPage;
