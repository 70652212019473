import ArtworkRenderer from './ArtworkRenderer';
import MakerRenderer from './MakerRenderer';

interface DynamicRendererProps {
  type: string;
  schema: string;
  data: any;
}

const rendererRegistry: Record<string, Record<string, React.FC<any>>> = {
  artwork: {
    '1': ArtworkRenderer,
  },
  maker: {
    '1': MakerRenderer,
  },
};

const DynamicRenderer: React.FC<DynamicRendererProps> = ({ type, schema, data }) => {
  const Renderer = rendererRegistry[type]?.[schema];

  if (!Renderer) {
    return (
      <p>
        Unsupported type or version: {type} - {schema}
      </p>
    );
  }

  return <Renderer data={data} />;
};

export default DynamicRenderer;
