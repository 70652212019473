import React, { useState } from 'react';
import { Spinner } from 'react-bootstrap';

import { useTranslateTextLazyQuery } from '../graphql/server-graphql-schema';

interface LanguageSelectorProps {
  text: string | null;
  ttsDescription: string | null;
  onUpdate: (lang: string, description: string | null, ttsDescription: string | null) => void;
}

const LANGUAGE_OPTIONS = [
  { code: 'en-US', label: 'English', flag: '🇺🇸' },
  { code: 'es-ES', label: 'Spanish - Spain', flag: '🇪🇸' },
  { code: 'it-IT', label: 'Italian', flag: '🇮🇹' },
];

const LanguageSelector: React.FC<LanguageSelectorProps> = ({ text, ttsDescription, onUpdate }) => {
  const [selectedLanguage, setSelectedLanguage] = useState<string>('en-US');
  const [translateText, { loading: translating }] = useTranslateTextLazyQuery();

  const handleLanguageChange = async (language: string) => {
    setSelectedLanguage(language);

    if (language !== 'en-US' && text) {
      try {
        const { data } = await translateText({
          variables: {
            input: {
              text,
              language,
            },
          },
        });

        if (data?.translateText?.text) {
          onUpdate(language, data.translateText.text, data.translateText.ttsDescription);
        }
      } catch (err) {
        console.error('Error translating text:', err);
      }
    } else {
      // Revert to original text for English
      onUpdate(selectedLanguage, text, ttsDescription);
    }
  };

  return (
    <div style={styles.container}>
      <select
        value={selectedLanguage}
        onChange={(e) => void handleLanguageChange(e.target.value)}
        disabled={translating}
        style={styles.flagDropdown}
      >
        {LANGUAGE_OPTIONS.map((lang) => (
          <option key={lang.code} value={lang.code}>
            {lang.flag}
          </option>
        ))}
      </select>
      {translating && <Spinner animation="border" size="sm" style={styles.spinner} />}
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  flagDropdown: {
    background: 'transparent',
    border: '1px solid #ccc',
    borderRadius: '8px',
    padding: '4px 8px',
    fontSize: '1rem',
    cursor: 'pointer',
    appearance: 'none', // Removes the default dropdown arrow for a cleaner look
  },
  spinner: {
    marginLeft: '8px',
  },
};

export default LanguageSelector;
