export const PRACTICE_SCHEMA = 2;
export const SENTENCE_SCHEMA = 1;
export const MINIMUM_PRACTICE_SCHEMA = 2;
export const AVAILABLE_LANGS = ['en-US', 'it-IT', 'ko-KO', 'fa-IR', 'zh-CN'];
export const LANG_MAP: Record<string, string> = {
  'es-MX': 'Spanish (Mexico)',
  'en-US': 'English (US)',
  'it-IT': 'Italian',
  'ko-KO': 'Korean',
  'fa-IR': 'Farsi',
  'zh-CN': 'Chinese Simplified',
};

export const CONTENT_BASE_URL = 'https://storage.googleapis.com/static.arthista.com/';

export enum AppSettingsType {
  PROMPTS = 'PROMPTS',
  CLIENT = 'CLIENT',
}

export const MAX_IMAGE_WIDTH = 1024;
export const MAX_IMAGE_HEIGHT = 1024;
export const MAX_IMAGE_SIZE = 1 * 1024 * 1024; // images above this size are resized
