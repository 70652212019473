import React from 'react';
import { Helmet } from 'react-helmet-async';

import { faBookOpen, faCamera, faPalette, faSearch, faShareAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const HomePage: React.FC = () => {
  return (
    <div>
      <Helmet>
        <title>Art Hista: Your Museum Guide</title>
      </Helmet>

      {/* Inline Styles for Conditional Layout */}
      <style>{`
        .floating-card {
          max-width: 550px;
          border-radius: 20px;
          margin-top: -70px;
          z-index: 100;
          padding: 1.5rem 2.5rem;
        }
        @media (max-width: 768px) {
          .floating-card {
            max-width: 330px;
            margin-top: -40px;
            padding: 1.5rem 1.3rem;
          }
        }
      `}</style>

      <div className="container-fluid p-0 my-5">
        <div className="position-relative">
          {/* Large Image */}
          <img
            srcSet={`${process.env.PUBLIC_URL}/images/people-talking1_low.jpg 500w,
            ${process.env.PUBLIC_URL}/images/people-talking1_medium.jpg 700w,
            ${process.env.PUBLIC_URL}/images/people-talking1_high.jpg 1200w`}
            sizes="(max-width: 600px) 300px,
            (max-width: 1200px) 900px,
            1200px"
            src={`${process.env.PUBLIC_URL}/images/people-talking1_high.jpg`}
            className="img-fluid rounded shadow w-100"
            alt="People discussing artwork in a museum"
            loading="lazy"
            style={{ maxHeight: '500px', objectFit: 'cover' }}
          />

          {/* Floating Text Content */}
          <div className="card bg-white shadow-lg mx-auto floating-card">
            <h1 className="display-5 fw-bold lh-1 mb-4 text-center">Your Personal Museum Guide</h1>
            <p className="lead mb-4 text-center">
              Explore museums like never before. With <strong>Art Hista</strong>, every visit becomes an immersive journey.
            </p>

            {/* Bullet Points */}
            <ul className="list-unstyled lead d-none d-lg-block">
              {/* Desktop Version */}
              <li className="mb-3">
                <FontAwesomeIcon icon={faPalette} className="me-2 text-primary" />
                Learn about artworks in your own language.
              </li>
              <li className="mb-3">
                <FontAwesomeIcon icon={faBookOpen} className="me-2 text-success" />
                Discover the artist's story and historical context.
              </li>
              <li className="mb-3">
                <FontAwesomeIcon icon={faSearch} className="me-2 text-info" />
                Uncover hidden details about exhibits.
              </li>
              <li className="mb-3">
                <FontAwesomeIcon icon={faCamera} className="me-2 text-warning" />
                Capture and relive your favorite moments.
              </li>
              <li>
                <FontAwesomeIcon icon={faShareAlt} className="me-2 text-danger" />
                Share your discoveries with ease.
              </li>
            </ul>

            <ul className="list-unstyled lead d-block d-lg-none">
              {/* Mobile Version */}
              <li className="mb-3">
                <FontAwesomeIcon icon={faPalette} className="me-2 text-primary" />
                Learn in your language.
              </li>
              <li className="mb-3">
                <FontAwesomeIcon icon={faBookOpen} className="me-2 text-success" />
                Discover artist stories.
              </li>
              <li className="mb-3">
                <FontAwesomeIcon icon={faSearch} className="me-2 text-info" />
                Uncover details.
              </li>
              <li className="mb-3">
                <FontAwesomeIcon icon={faCamera} className="me-2 text-warning" />
                Capture moments.
              </li>
              <li>
                <FontAwesomeIcon icon={faShareAlt} className="me-2 text-danger" />
                Share discoveries.
              </li>
            </ul>

            {/* Call-to-Action Section */}
            <div className="mt-5 text-center">
              <h4 className="mb-4">Coming soon to your phone!</h4>
              <div className="d-flex justify-content-center gap-4 text-muted" style={{ opacity: 0.3 }}>
                <a
                  href="https://apps.apple.com/eg/app/lengua-learn-spanish/id6469734069"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="d-inline-block"
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg`}
                    alt="Download on the App Store"
                    style={{ height: '60px', maxWidth: '100%' }}
                  />
                </a>

                <a
                  href="https://play.google.com/apps/testing/app.lengua"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="d-inline-block"
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/images/PreRegisterOnGooglePlay_Badge_Web_color_english.png.png`}
                    alt="Get it on Google Play"
                    style={{ height: '60px', maxWidth: '100%' }}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
