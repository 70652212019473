import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  JSON: { input: any; output: any; }
};

export type AdminSettings = {
  __typename?: 'AdminSettings';
  data: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type AdminSettingsInput = {
  data: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type AppSettings = {
  __typename?: 'AppSettings';
  availableLangs: Array<Scalars['String']['output']>;
  contentFiles: Array<Scalars['String']['output']>;
  userSettings?: Maybe<UserSettings>;
};

export type Artwork = {
  __typename?: 'Artwork';
  archiveId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  dateStarted?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  location?: Maybe<Location>;
  locationName?: Maybe<Scalars['String']['output']>;
  maker?: Maybe<Maker>;
  makerName?: Maybe<Scalars['String']['output']>;
  media?: Maybe<Array<Maybe<Media>>>;
  meta?: Maybe<Scalars['JSON']['output']>;
  text?: Maybe<TextContent>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type ArtworkLocalizedText = {
  description?: InputMaybe<Scalars['String']['input']>;
  lang: Scalars['String']['input'];
  locationName?: InputMaybe<Scalars['String']['input']>;
  makerName?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type ArtworkLocalizedTextInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  lang: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type ArtworkPagination = {
  __typename?: 'ArtworkPagination';
  artworks: Array<Artwork>;
  hasMore: Scalars['Boolean']['output'];
  total: Scalars['Int']['output'];
};

export type Audio = {
  __typename?: 'Audio';
  outdated?: Maybe<Scalars['Boolean']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  voiceName?: Maybe<Scalars['String']['output']>;
};

export type AudioInput = {
  outdated?: InputMaybe<Scalars['Boolean']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  voiceName?: InputMaybe<Scalars['String']['input']>;
};

export type CreateArtworkSimpleInput = {
  archiveId?: InputMaybe<Scalars['String']['input']>;
  dateStarted?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  text: Array<ArtworkLocalizedText>;
};

export type CreateLocationInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  country: Scalars['String']['input'];
  name: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type EditArtworkInput = {
  archiveId?: InputMaybe<Scalars['String']['input']>;
  dateStarted?: InputMaybe<Scalars['String']['input']>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
  locationName?: InputMaybe<Scalars['String']['input']>;
  makerId?: InputMaybe<Scalars['ID']['input']>;
  makerName?: InputMaybe<Scalars['String']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  text?: InputMaybe<ArtworkLocalizedTextInput>;
};

export type ExtractArtworkInfoInput = {
  location?: InputMaybe<Scalars['String']['input']>;
  text: Scalars['String']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
};

export type ExtractArtworkInfoResponse = {
  __typename?: 'ExtractArtworkInfoResponse';
  artworks: Array<OcrArtwork>;
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type FieldLengthContent = {
  __typename?: 'FieldLengthContent';
  audio?: Maybe<Array<Maybe<Audio>>>;
  text: Scalars['String']['output'];
};

export type FieldLengthContentInput = {
  audio?: InputMaybe<Array<InputMaybe<AudioInput>>>;
  text: Scalars['String']['input'];
};

export type GenerateSignedUploadUrlInput = {
  contentType: Scalars['String']['input'];
};

export type GetArtworksOrMakersInput = {
  search: Scalars['String']['input'];
};

export type GetArtworksOrMakersResponse = {
  __typename?: 'GetArtworksOrMakersResponse';
  artworks: Array<OaiArtwork>;
  makers: Array<OaiMaker>;
};

export type Location = {
  __typename?: 'Location';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  user: User;
};

export type LocationPagination = {
  __typename?: 'LocationPagination';
  hasMore: Scalars['Boolean']['output'];
  locations: Array<Location>;
  total: Scalars['Int']['output'];
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  avatar?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  jwt: Scalars['String']['output'];
  name: Scalars['String']['output'];
  profile?: Maybe<Scalars['JSON']['output']>;
  role: Scalars['String']['output'];
};

export type Maker = {
  __typename?: 'Maker';
  birthPlace?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  dateOfBirth?: Maybe<Scalars['String']['output']>;
  dateOfDeath?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  meta?: Maybe<Scalars['JSON']['output']>;
  text?: Maybe<TextContent>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type MakerPagination = {
  __typename?: 'MakerPagination';
  hasMore: Scalars['Boolean']['output'];
  makers: Array<Maker>;
  total: Scalars['Int']['output'];
};

export type Media = {
  __typename?: 'Media';
  anchorText?: Maybe<Scalars['String']['output']>;
  artwork?: Maybe<Artwork>;
  createdAt?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  meta?: Maybe<Scalars['JSON']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  type: MediaType;
  updatedAt?: Maybe<Scalars['String']['output']>;
  urls?: Maybe<MediaUrls>;
  user?: Maybe<User>;
};

export enum MediaType {
  Image = 'IMAGE',
  Video = 'VIDEO'
}

export type MediaUrls = {
  __typename?: 'MediaUrls';
  comp?: Maybe<Scalars['String']['output']>;
  orig?: Maybe<Scalars['String']['output']>;
  thumb?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  anonymizeUser: User;
  createArtworkSimple: Artwork;
  createLocation: Location;
  deleteAdminSettings?: Maybe<Scalars['Boolean']['output']>;
  deleteArtwork: Scalars['Boolean']['output'];
  deleteLocation: Scalars['Boolean']['output'];
  deleteMaker: Scalars['Boolean']['output'];
  deleteMedia: Scalars['Boolean']['output'];
  deleteUser: Scalars['Boolean']['output'];
  editArtwork: Artwork;
  extractArtworkInfoFromImage: ExtractArtworkInfoResponse;
  insertAdminSettings?: Maybe<AdminSettings>;
  login: LoginResponse;
  processUploadedMedia: Media;
  promoteMedia: Scalars['Boolean']['output'];
  syncSettings?: Maybe<AppSettings>;
  synthesizeVoice: SynthesizeVoiceResponse;
  updateLocation: Location;
  updateUser: User;
  upsertAdminSettings?: Maybe<AdminSettings>;
  upsertMaker: Maker;
};


export type MutationAnonymizeUserArgs = {
  id: Scalars['ID']['input'];
};


export type MutationCreateArtworkSimpleArgs = {
  input: CreateArtworkSimpleInput;
};


export type MutationCreateLocationArgs = {
  input: CreateLocationInput;
};


export type MutationDeleteAdminSettingsArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteArtworkArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteLocationArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteMakerArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteMediaArgs = {
  mediaId: Scalars['ID']['input'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['ID']['input'];
};


export type MutationEditArtworkArgs = {
  id: Scalars['ID']['input'];
  input: EditArtworkInput;
};


export type MutationExtractArtworkInfoFromImageArgs = {
  input: ReadImageInfoInput;
};


export type MutationInsertAdminSettingsArgs = {
  input: AdminSettingsInput;
};


export type MutationLoginArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  source: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationProcessUploadedMediaArgs = {
  input: ProcessUploadedMediaInput;
};


export type MutationPromoteMediaArgs = {
  mediaId: Scalars['ID']['input'];
};


export type MutationSyncSettingsArgs = {
  lang?: InputMaybe<Scalars['String']['input']>;
  transLangs?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  userSettings?: InputMaybe<UserSettingsInput>;
};


export type MutationSynthesizeVoiceArgs = {
  input: SynthesizeVoiceInput;
};


export type MutationUpdateLocationArgs = {
  id: Scalars['ID']['input'];
  input: UpdateLocationInput;
};


export type MutationUpdateUserArgs = {
  avatar?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpsertAdminSettingsArgs = {
  input: AdminSettingsInput;
};


export type MutationUpsertMakerArgs = {
  input: UpsertMakerInput;
};

export type NameLengthContent = {
  __typename?: 'NameLengthContent';
  default?: Maybe<FieldLengthContent>;
  long?: Maybe<FieldLengthContent>;
  short?: Maybe<FieldLengthContent>;
};

export type NameLengthContentInput = {
  default: FieldLengthContentInput;
  long?: InputMaybe<FieldLengthContentInput>;
  short?: InputMaybe<FieldLengthContentInput>;
};

export type OaiArtwork = {
  __typename?: 'OaiArtwork';
  artistName?: Maybe<Scalars['String']['output']>;
  dateStarted?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  materials?: Maybe<Scalars['String']['output']>;
  medium?: Maybe<Scalars['String']['output']>;
  movement?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  relations?: Maybe<Relation>;
  schema: Scalars['String']['output'];
  technique?: Maybe<Scalars['String']['output']>;
  ttsDescription?: Maybe<Scalars['String']['output']>;
};

export type OaiMaker = {
  __typename?: 'OaiMaker';
  birthPlace?: Maybe<Scalars['String']['output']>;
  dateOfBirth?: Maybe<Scalars['String']['output']>;
  dateOfDeath?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  movements?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  name?: Maybe<Scalars['String']['output']>;
  schema: Scalars['String']['output'];
  ttsDescription?: Maybe<Scalars['String']['output']>;
};

export type OcrArtwork = {
  __typename?: 'OcrArtwork';
  archiveId?: Maybe<Scalars['String']['output']>;
  artistNames?: Maybe<Array<OcrLocalizedText>>;
  artworkLocation?: Maybe<Scalars['String']['output']>;
  artworkNames?: Maybe<Array<OcrLocalizedText>>;
  dateOfBirthOfArtist?: Maybe<Scalars['String']['output']>;
  dateOfCreation?: Maybe<Scalars['String']['output']>;
  dateOfDeathOfArtist?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Array<OcrLocalizedText>>;
  id?: Maybe<Scalars['ID']['output']>;
  locationOfBirthOfArtist?: Maybe<Scalars['String']['output']>;
  locationOfDeathOfArtist?: Maybe<Scalars['String']['output']>;
  material?: Maybe<Scalars['String']['output']>;
};

export type OcrLocalizedText = {
  __typename?: 'OcrLocalizedText';
  lang: Scalars['String']['output'];
  text?: Maybe<Scalars['String']['output']>;
};

export type ProcessUploadedMediaInput = {
  artworkId?: InputMaybe<Scalars['ID']['input']>;
  fileName: Scalars['String']['input'];
  type: MediaType;
};

export type Query = {
  __typename?: 'Query';
  appSettings?: Maybe<AppSettings>;
  extractArtworkInfo: ExtractArtworkInfoResponse;
  generateSignedUploadUrl: SignedUploadUrl;
  getAdminSettings: Array<AdminSettings>;
  getAdminSettingsById?: Maybe<AdminSettings>;
  getAdminSettingsByType: Array<AdminSettings>;
  getAllArtworks: ArtworkPagination;
  getAllLocations: LocationPagination;
  getAllMakers: MakerPagination;
  getArtworkById: Artwork;
  getArtworksOrMakers: GetArtworksOrMakersResponse;
  getLocationById: Location;
  getMakerById: Maker;
  translateText: TranslateTextResponse;
  user: User;
  userReports: UserPagination;
};


export type QueryAppSettingsArgs = {
  lang?: InputMaybe<Scalars['String']['input']>;
  transLangs?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryExtractArtworkInfoArgs = {
  input: ExtractArtworkInfoInput;
};


export type QueryGenerateSignedUploadUrlArgs = {
  input: GenerateSignedUploadUrlInput;
};


export type QueryGetAdminSettingsByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetAdminSettingsByTypeArgs = {
  type: Scalars['String']['input'];
};


export type QueryGetAllArtworksArgs = {
  lang?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetAllLocationsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetAllMakersArgs = {
  lang?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetArtworkByIdArgs = {
  id: Scalars['ID']['input'];
  lang?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetArtworksOrMakersArgs = {
  input: GetArtworksOrMakersInput;
};


export type QueryGetLocationByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetMakerByIdArgs = {
  id: Scalars['ID']['input'];
  lang?: InputMaybe<Scalars['String']['input']>;
};


export type QueryTranslateTextArgs = {
  input: TranslateTextInput;
};


export type QueryUserArgs = {
  id: Scalars['ID']['input'];
  includeReportCount?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryUserReportsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort: SortInput;
};

export type ReadImageInfoInput = {
  imagePath: Scalars['String']['input'];
  location?: InputMaybe<Scalars['String']['input']>;
};

export type Relation = {
  __typename?: 'Relation';
  controversy?: Maybe<Scalars['String']['output']>;
  culturalSensitive?: Maybe<Scalars['String']['output']>;
  eventDepicted?: Maybe<Scalars['String']['output']>;
  historical?: Maybe<Scalars['String']['output']>;
  iconography?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  people?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  politicalStatement?: Maybe<Scalars['String']['output']>;
  religiousTheme?: Maybe<Scalars['String']['output']>;
  socioPolitical?: Maybe<Scalars['String']['output']>;
  symbolism?: Maybe<Scalars['String']['output']>;
  theme?: Maybe<Scalars['String']['output']>;
};

export type SentenceInput = {
  id: Scalars['String']['input'];
  lang: Scalars['String']['input'];
  meta?: InputMaybe<Scalars['JSON']['input']>;
  text: Scalars['String']['input'];
};

export type SentenceSetSettings = {
  __typename?: 'SentenceSetSettings';
  lastUpdated: Scalars['Int']['output'];
  maxSentencesInSet: Scalars['Int']['output'];
  numCorrectToPass: Scalars['Int']['output'];
};

export type SentenceSetSettingsInput = {
  lastUpdated: Scalars['Int']['input'];
  maxSentencesInSet: Scalars['Int']['input'];
  numCorrectToPass: Scalars['Int']['input'];
  setId: Scalars['String']['input'];
};

export type SentenceSetSettingsMap = {
  __typename?: 'SentenceSetSettingsMap';
  setId: Scalars['String']['output'];
  settings: SentenceSetSettings;
};

export type SignedUploadUrl = {
  __typename?: 'SignedUploadUrl';
  fileName: Scalars['String']['output'];
  signedUrl: Scalars['String']['output'];
};

export type SortInput = {
  direction: Scalars['String']['input'];
  field: Scalars['String']['input'];
};

export type SynthesizeVoiceInput = {
  id: Scalars['String']['input'];
  lang: Scalars['String']['input'];
  override?: InputMaybe<Scalars['Boolean']['input']>;
  text: Scalars['String']['input'];
};

export type SynthesizeVoiceResponse = {
  __typename?: 'SynthesizeVoiceResponse';
  url: Scalars['String']['output'];
};

export type TextContent = {
  __typename?: 'TextContent';
  description?: Maybe<NameLengthContent>;
  name?: Maybe<NameLengthContent>;
};

export type TextContentInput = {
  description?: InputMaybe<NameLengthContentInput>;
  name?: InputMaybe<NameLengthContentInput>;
};

export type TranslateTextInput = {
  language: Scalars['String']['input'];
  text: Scalars['String']['input'];
};

export type TranslateTextResponse = {
  __typename?: 'TranslateTextResponse';
  schema: Scalars['String']['output'];
  text: Scalars['String']['output'];
  ttsDescription: Scalars['String']['output'];
};

export type UpdateLocationInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertMakerInput = {
  birthPlace?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  dateOfDeath?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  lang: Scalars['String']['input'];
  meta?: InputMaybe<Scalars['JSON']['input']>;
  name: Scalars['String']['input'];
};

export type User = {
  __typename?: 'User';
  artworks?: Maybe<Array<Artwork>>;
  avatar?: Maybe<Scalars['String']['output']>;
  createdDate?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  makers?: Maybe<Array<Maker>>;
  media?: Maybe<Array<Media>>;
  name?: Maybe<Scalars['String']['output']>;
  profile?: Maybe<Scalars['JSON']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  updatedDate?: Maybe<Scalars['String']['output']>;
};

export type UserPagination = {
  __typename?: 'UserPagination';
  hasMore: Scalars['Boolean']['output'];
  total: Scalars['Int']['output'];
  users: Array<User>;
};

export type UserSettings = {
  __typename?: 'UserSettings';
  sentenceSetSettings: Array<SentenceSetSettingsMap>;
};

export type UserSettingsInput = {
  sentenceSetSettings: Array<SentenceSetSettingsInput>;
};

export type AdminSettingsFieldsFragment = { __typename?: 'AdminSettings', id: string, name: string, type: string, data: string };

export type GetAdminSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAdminSettingsQuery = { __typename?: 'Query', getAdminSettings: Array<{ __typename?: 'AdminSettings', id: string, name: string, type: string, data: string }> };

export type GetAdminSettingsByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetAdminSettingsByIdQuery = { __typename?: 'Query', getAdminSettingsById?: { __typename?: 'AdminSettings', id: string, name: string, type: string, data: string } | null };

export type UpsertAdminSettingsMutationVariables = Exact<{
  input: AdminSettingsInput;
}>;


export type UpsertAdminSettingsMutation = { __typename?: 'Mutation', upsertAdminSettings?: { __typename?: 'AdminSettings', id: string, name: string, type: string, data: string } | null };

export type DeleteAdminSettingsMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteAdminSettingsMutation = { __typename?: 'Mutation', deleteAdminSettings?: boolean | null };

export type TextContentFragmentFragment = { __typename?: 'TextContent', name?: { __typename?: 'NameLengthContent', default?: { __typename?: 'FieldLengthContent', text: string, audio?: Array<{ __typename?: 'Audio', voiceName?: string | null, url?: string | null } | null> | null } | null, short?: { __typename?: 'FieldLengthContent', text: string, audio?: Array<{ __typename?: 'Audio', voiceName?: string | null, url?: string | null } | null> | null } | null, long?: { __typename?: 'FieldLengthContent', text: string, audio?: Array<{ __typename?: 'Audio', voiceName?: string | null, url?: string | null } | null> | null } | null } | null, description?: { __typename?: 'NameLengthContent', default?: { __typename?: 'FieldLengthContent', text: string, audio?: Array<{ __typename?: 'Audio', voiceName?: string | null, url?: string | null } | null> | null } | null, short?: { __typename?: 'FieldLengthContent', text: string, audio?: Array<{ __typename?: 'Audio', voiceName?: string | null, url?: string | null } | null> | null } | null, long?: { __typename?: 'FieldLengthContent', text: string, audio?: Array<{ __typename?: 'Audio', voiceName?: string | null, url?: string | null } | null> | null } | null } | null };

export type GetAllArtworksQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  lang?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetAllArtworksQuery = { __typename?: 'Query', getAllArtworks: { __typename?: 'ArtworkPagination', total: number, hasMore: boolean, artworks: Array<{ __typename?: 'Artwork', id: string, archiveId?: string | null, makerName?: string | null, locationName?: string | null, dateStarted?: string | null, meta?: any | null, maker?: { __typename?: 'Maker', id: string, text?: { __typename?: 'TextContent', name?: { __typename?: 'NameLengthContent', default?: { __typename?: 'FieldLengthContent', text: string } | null } | null } | null } | null, location?: { __typename?: 'Location', id: string, name: string } | null, media?: Array<{ __typename?: 'Media', urls?: { __typename?: 'MediaUrls', thumb?: string | null } | null } | null> | null, text?: { __typename?: 'TextContent', name?: { __typename?: 'NameLengthContent', default?: { __typename?: 'FieldLengthContent', text: string, audio?: Array<{ __typename?: 'Audio', voiceName?: string | null, url?: string | null } | null> | null } | null, short?: { __typename?: 'FieldLengthContent', text: string, audio?: Array<{ __typename?: 'Audio', voiceName?: string | null, url?: string | null } | null> | null } | null, long?: { __typename?: 'FieldLengthContent', text: string, audio?: Array<{ __typename?: 'Audio', voiceName?: string | null, url?: string | null } | null> | null } | null } | null, description?: { __typename?: 'NameLengthContent', default?: { __typename?: 'FieldLengthContent', text: string, audio?: Array<{ __typename?: 'Audio', voiceName?: string | null, url?: string | null } | null> | null } | null, short?: { __typename?: 'FieldLengthContent', text: string, audio?: Array<{ __typename?: 'Audio', voiceName?: string | null, url?: string | null } | null> | null } | null, long?: { __typename?: 'FieldLengthContent', text: string, audio?: Array<{ __typename?: 'Audio', voiceName?: string | null, url?: string | null } | null> | null } | null } | null } | null }> } };

export type GetArtworkByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  lang?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetArtworkByIdQuery = { __typename?: 'Query', getArtworkById: { __typename?: 'Artwork', id: string, archiveId?: string | null, makerName?: string | null, locationName?: string | null, dateStarted?: string | null, meta?: any | null, maker?: { __typename?: 'Maker', id: string, text?: { __typename?: 'TextContent', name?: { __typename?: 'NameLengthContent', default?: { __typename?: 'FieldLengthContent', text: string } | null } | null } | null } | null, location?: { __typename?: 'Location', id: string, name: string, city?: string | null, country?: string | null, type?: string | null } | null, media?: Array<{ __typename?: 'Media', id: string, type: MediaType, order?: number | null, meta?: any | null, urls?: { __typename?: 'MediaUrls', thumb?: string | null } | null } | null> | null, text?: { __typename?: 'TextContent', name?: { __typename?: 'NameLengthContent', default?: { __typename?: 'FieldLengthContent', text: string, audio?: Array<{ __typename?: 'Audio', voiceName?: string | null, url?: string | null } | null> | null } | null, short?: { __typename?: 'FieldLengthContent', text: string, audio?: Array<{ __typename?: 'Audio', voiceName?: string | null, url?: string | null } | null> | null } | null, long?: { __typename?: 'FieldLengthContent', text: string, audio?: Array<{ __typename?: 'Audio', voiceName?: string | null, url?: string | null } | null> | null } | null } | null, description?: { __typename?: 'NameLengthContent', default?: { __typename?: 'FieldLengthContent', text: string, audio?: Array<{ __typename?: 'Audio', voiceName?: string | null, url?: string | null } | null> | null } | null, short?: { __typename?: 'FieldLengthContent', text: string, audio?: Array<{ __typename?: 'Audio', voiceName?: string | null, url?: string | null } | null> | null } | null, long?: { __typename?: 'FieldLengthContent', text: string, audio?: Array<{ __typename?: 'Audio', voiceName?: string | null, url?: string | null } | null> | null } | null } | null } | null } };

export type CreateArtworkSimpleMutationVariables = Exact<{
  input: CreateArtworkSimpleInput;
}>;


export type CreateArtworkSimpleMutation = { __typename?: 'Mutation', createArtworkSimple: { __typename?: 'Artwork', id: string, archiveId?: string | null, makerName?: string | null, locationName?: string | null, dateStarted?: string | null, meta?: any | null, text?: { __typename?: 'TextContent', name?: { __typename?: 'NameLengthContent', default?: { __typename?: 'FieldLengthContent', text: string } | null } | null, description?: { __typename?: 'NameLengthContent', default?: { __typename?: 'FieldLengthContent', text: string } | null } | null } | null } };

export type GetAllMakersAndLocationsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  lang?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetAllMakersAndLocationsQuery = { __typename?: 'Query', getAllLocations: { __typename?: 'LocationPagination', total: number, hasMore: boolean, locations: Array<{ __typename?: 'Location', id: string, name: string, city?: string | null, country?: string | null, type?: string | null }> }, getAllMakers: { __typename?: 'MakerPagination', total: number, hasMore: boolean, makers: Array<{ __typename?: 'Maker', id: string, birthPlace?: string | null, text?: { __typename?: 'TextContent', name?: { __typename?: 'NameLengthContent', default?: { __typename?: 'FieldLengthContent', text: string } | null } | null } | null }> } };

export type EditArtworkMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: EditArtworkInput;
}>;


export type EditArtworkMutation = { __typename?: 'Mutation', editArtwork: { __typename?: 'Artwork', id: string, archiveId?: string | null, makerName?: string | null, locationName?: string | null, dateStarted?: string | null, meta?: any | null, text?: { __typename?: 'TextContent', name?: { __typename?: 'NameLengthContent', default?: { __typename?: 'FieldLengthContent', text: string } | null } | null, description?: { __typename?: 'NameLengthContent', default?: { __typename?: 'FieldLengthContent', text: string } | null } | null } | null } };

export type DeleteArtworkMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteArtworkMutation = { __typename?: 'Mutation', deleteArtwork: boolean };

export type GetAllLocationsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetAllLocationsQuery = { __typename?: 'Query', getAllLocations: { __typename?: 'LocationPagination', total: number, hasMore: boolean, locations: Array<{ __typename?: 'Location', id: string, name: string, city?: string | null, country?: string | null, type?: string | null }> } };

export type CreateLocationMutationVariables = Exact<{
  input: CreateLocationInput;
}>;


export type CreateLocationMutation = { __typename?: 'Mutation', createLocation: { __typename?: 'Location', id: string, name: string, city?: string | null, country?: string | null, type?: string | null, createdAt?: string | null, updatedAt?: string | null } };

export type DeleteLocationMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteLocationMutation = { __typename?: 'Mutation', deleteLocation: boolean };

export type GetAllMakersQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  lang?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetAllMakersQuery = { __typename?: 'Query', getAllMakers: { __typename?: 'MakerPagination', total: number, hasMore: boolean, makers: Array<{ __typename?: 'Maker', id: string, birthPlace?: string | null, dateOfBirth?: string | null, dateOfDeath?: string | null, text?: { __typename?: 'TextContent', name?: { __typename?: 'NameLengthContent', default?: { __typename?: 'FieldLengthContent', text: string } | null } | null, description?: { __typename?: 'NameLengthContent', default?: { __typename?: 'FieldLengthContent', text: string } | null } | null } | null }> } };

export type UpsertMakerMutationVariables = Exact<{
  input: UpsertMakerInput;
}>;


export type UpsertMakerMutation = { __typename?: 'Mutation', upsertMaker: { __typename?: 'Maker', id: string, dateOfBirth?: string | null, dateOfDeath?: string | null, birthPlace?: string | null, meta?: any | null, createdAt?: string | null, updatedAt?: string | null, user?: { __typename?: 'User', id: string } | null, text?: { __typename?: 'TextContent', name?: { __typename?: 'NameLengthContent', default?: { __typename?: 'FieldLengthContent', text: string, audio?: Array<{ __typename?: 'Audio', voiceName?: string | null, url?: string | null } | null> | null } | null, short?: { __typename?: 'FieldLengthContent', text: string, audio?: Array<{ __typename?: 'Audio', voiceName?: string | null, url?: string | null } | null> | null } | null, long?: { __typename?: 'FieldLengthContent', text: string, audio?: Array<{ __typename?: 'Audio', voiceName?: string | null, url?: string | null } | null> | null } | null } | null, description?: { __typename?: 'NameLengthContent', default?: { __typename?: 'FieldLengthContent', text: string, audio?: Array<{ __typename?: 'Audio', voiceName?: string | null, url?: string | null } | null> | null } | null, short?: { __typename?: 'FieldLengthContent', text: string, audio?: Array<{ __typename?: 'Audio', voiceName?: string | null, url?: string | null } | null> | null } | null, long?: { __typename?: 'FieldLengthContent', text: string, audio?: Array<{ __typename?: 'Audio', voiceName?: string | null, url?: string | null } | null> | null } | null } | null } | null } };

export type DeleteMakerMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteMakerMutation = { __typename?: 'Mutation', deleteMaker: boolean };

export type GenerateSignedUploadUrlQueryVariables = Exact<{
  input: GenerateSignedUploadUrlInput;
}>;


export type GenerateSignedUploadUrlQuery = { __typename?: 'Query', generateSignedUploadUrl: { __typename?: 'SignedUploadUrl', signedUrl: string, fileName: string } };

export type ProcessUploadedMediaMutationVariables = Exact<{
  input: ProcessUploadedMediaInput;
}>;


export type ProcessUploadedMediaMutation = { __typename?: 'Mutation', processUploadedMedia: { __typename?: 'Media', id: string, type: MediaType, meta?: any | null, artwork?: { __typename?: 'Artwork', id: string } | null, urls?: { __typename?: 'MediaUrls', thumb?: string | null } | null } };

export type DeleteMediaMutationVariables = Exact<{
  mediaId: Scalars['ID']['input'];
}>;


export type DeleteMediaMutation = { __typename?: 'Mutation', deleteMedia: boolean };

export type PromoteMediaMutationVariables = Exact<{
  mediaId: Scalars['ID']['input'];
}>;


export type PromoteMediaMutation = { __typename?: 'Mutation', promoteMedia: boolean };

export type ExtractArtworkInfoFromImageMutationVariables = Exact<{
  input: ReadImageInfoInput;
}>;


export type ExtractArtworkInfoFromImageMutation = { __typename?: 'Mutation', extractArtworkInfoFromImage: { __typename?: 'ExtractArtworkInfoResponse', success?: boolean | null, error?: string | null, artworks: Array<{ __typename?: 'OcrArtwork', id?: string | null, archiveId?: string | null, dateOfBirthOfArtist?: string | null, dateOfDeathOfArtist?: string | null, locationOfBirthOfArtist?: string | null, locationOfDeathOfArtist?: string | null, dateOfCreation?: string | null, material?: string | null, artworkLocation?: string | null, artworkNames?: Array<{ __typename?: 'OcrLocalizedText', text?: string | null, lang: string }> | null, artistNames?: Array<{ __typename?: 'OcrLocalizedText', text?: string | null, lang: string }> | null, description?: Array<{ __typename?: 'OcrLocalizedText', text?: string | null, lang: string }> | null }> } };

export type GetArtworksOrMakersQueryVariables = Exact<{
  input: GetArtworksOrMakersInput;
}>;


export type GetArtworksOrMakersQuery = { __typename?: 'Query', getArtworksOrMakers: { __typename?: 'GetArtworksOrMakersResponse', artworks: Array<{ __typename?: 'OaiArtwork', schema: string, name?: string | null, description?: string | null, ttsDescription?: string | null, artistName?: string | null, movement?: string | null, dateStarted?: string | null, location?: string | null, medium?: string | null, technique?: string | null, materials?: string | null, relations?: { __typename?: 'Relation', people?: Array<string | null> | null, location?: string | null, theme?: string | null, eventDepicted?: string | null, iconography?: string | null, controversy?: string | null, socioPolitical?: string | null, historical?: string | null, culturalSensitive?: string | null, politicalStatement?: string | null, religiousTheme?: string | null, symbolism?: string | null } | null }>, makers: Array<{ __typename?: 'OaiMaker', schema: string, name?: string | null, description?: string | null, ttsDescription?: string | null, movements?: Array<string | null> | null, dateOfBirth?: string | null, dateOfDeath?: string | null, birthPlace?: string | null }> } };

export type TranslateTextQueryVariables = Exact<{
  input: TranslateTextInput;
}>;


export type TranslateTextQuery = { __typename?: 'Query', translateText: { __typename?: 'TranslateTextResponse', text: string, ttsDescription: string, schema: string } };

export type SynthesizeVoiceMutationVariables = Exact<{
  input: SynthesizeVoiceInput;
}>;


export type SynthesizeVoiceMutation = { __typename?: 'Mutation', synthesizeVoice: { __typename?: 'SynthesizeVoiceResponse', url: string } };

export type LoginMutationVariables = Exact<{
  token: Scalars['String']['input'];
  source: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'LoginResponse', id: string, name: string, email: string, avatar?: string | null, role: string, jwt: string } };

export const AdminSettingsFieldsFragmentDoc = gql`
    fragment AdminSettingsFields on AdminSettings {
  id
  name
  type
  data
}
    `;
export const TextContentFragmentFragmentDoc = gql`
    fragment TextContentFragment on TextContent {
  name {
    default {
      text
      audio {
        voiceName
        url
      }
    }
    short {
      text
      audio {
        voiceName
        url
      }
    }
    long {
      text
      audio {
        voiceName
        url
      }
    }
  }
  description {
    default {
      text
      audio {
        voiceName
        url
      }
    }
    short {
      text
      audio {
        voiceName
        url
      }
    }
    long {
      text
      audio {
        voiceName
        url
      }
    }
  }
}
    `;
export const GetAdminSettingsDocument = gql`
    query GetAdminSettings {
  getAdminSettings {
    ...AdminSettingsFields
  }
}
    ${AdminSettingsFieldsFragmentDoc}`;

/**
 * __useGetAdminSettingsQuery__
 *
 * To run a query within a React component, call `useGetAdminSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAdminSettingsQuery(baseOptions?: Apollo.QueryHookOptions<GetAdminSettingsQuery, GetAdminSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdminSettingsQuery, GetAdminSettingsQueryVariables>(GetAdminSettingsDocument, options);
      }
export function useGetAdminSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminSettingsQuery, GetAdminSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdminSettingsQuery, GetAdminSettingsQueryVariables>(GetAdminSettingsDocument, options);
        }
export function useGetAdminSettingsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAdminSettingsQuery, GetAdminSettingsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAdminSettingsQuery, GetAdminSettingsQueryVariables>(GetAdminSettingsDocument, options);
        }
export type GetAdminSettingsQueryHookResult = ReturnType<typeof useGetAdminSettingsQuery>;
export type GetAdminSettingsLazyQueryHookResult = ReturnType<typeof useGetAdminSettingsLazyQuery>;
export type GetAdminSettingsSuspenseQueryHookResult = ReturnType<typeof useGetAdminSettingsSuspenseQuery>;
export type GetAdminSettingsQueryResult = Apollo.QueryResult<GetAdminSettingsQuery, GetAdminSettingsQueryVariables>;
export const GetAdminSettingsByIdDocument = gql`
    query GetAdminSettingsById($id: ID!) {
  getAdminSettingsById(id: $id) {
    ...AdminSettingsFields
  }
}
    ${AdminSettingsFieldsFragmentDoc}`;

/**
 * __useGetAdminSettingsByIdQuery__
 *
 * To run a query within a React component, call `useGetAdminSettingsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminSettingsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminSettingsByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAdminSettingsByIdQuery(baseOptions: Apollo.QueryHookOptions<GetAdminSettingsByIdQuery, GetAdminSettingsByIdQueryVariables> & ({ variables: GetAdminSettingsByIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdminSettingsByIdQuery, GetAdminSettingsByIdQueryVariables>(GetAdminSettingsByIdDocument, options);
      }
export function useGetAdminSettingsByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminSettingsByIdQuery, GetAdminSettingsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdminSettingsByIdQuery, GetAdminSettingsByIdQueryVariables>(GetAdminSettingsByIdDocument, options);
        }
export function useGetAdminSettingsByIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAdminSettingsByIdQuery, GetAdminSettingsByIdQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAdminSettingsByIdQuery, GetAdminSettingsByIdQueryVariables>(GetAdminSettingsByIdDocument, options);
        }
export type GetAdminSettingsByIdQueryHookResult = ReturnType<typeof useGetAdminSettingsByIdQuery>;
export type GetAdminSettingsByIdLazyQueryHookResult = ReturnType<typeof useGetAdminSettingsByIdLazyQuery>;
export type GetAdminSettingsByIdSuspenseQueryHookResult = ReturnType<typeof useGetAdminSettingsByIdSuspenseQuery>;
export type GetAdminSettingsByIdQueryResult = Apollo.QueryResult<GetAdminSettingsByIdQuery, GetAdminSettingsByIdQueryVariables>;
export const UpsertAdminSettingsDocument = gql`
    mutation UpsertAdminSettings($input: AdminSettingsInput!) {
  upsertAdminSettings(input: $input) {
    ...AdminSettingsFields
  }
}
    ${AdminSettingsFieldsFragmentDoc}`;
export type UpsertAdminSettingsMutationFn = Apollo.MutationFunction<UpsertAdminSettingsMutation, UpsertAdminSettingsMutationVariables>;

/**
 * __useUpsertAdminSettingsMutation__
 *
 * To run a mutation, you first call `useUpsertAdminSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertAdminSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertAdminSettingsMutation, { data, loading, error }] = useUpsertAdminSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertAdminSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpsertAdminSettingsMutation, UpsertAdminSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertAdminSettingsMutation, UpsertAdminSettingsMutationVariables>(UpsertAdminSettingsDocument, options);
      }
export type UpsertAdminSettingsMutationHookResult = ReturnType<typeof useUpsertAdminSettingsMutation>;
export type UpsertAdminSettingsMutationResult = Apollo.MutationResult<UpsertAdminSettingsMutation>;
export type UpsertAdminSettingsMutationOptions = Apollo.BaseMutationOptions<UpsertAdminSettingsMutation, UpsertAdminSettingsMutationVariables>;
export const DeleteAdminSettingsDocument = gql`
    mutation DeleteAdminSettings($id: ID!) {
  deleteAdminSettings(id: $id)
}
    `;
export type DeleteAdminSettingsMutationFn = Apollo.MutationFunction<DeleteAdminSettingsMutation, DeleteAdminSettingsMutationVariables>;

/**
 * __useDeleteAdminSettingsMutation__
 *
 * To run a mutation, you first call `useDeleteAdminSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAdminSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAdminSettingsMutation, { data, loading, error }] = useDeleteAdminSettingsMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAdminSettingsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAdminSettingsMutation, DeleteAdminSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAdminSettingsMutation, DeleteAdminSettingsMutationVariables>(DeleteAdminSettingsDocument, options);
      }
export type DeleteAdminSettingsMutationHookResult = ReturnType<typeof useDeleteAdminSettingsMutation>;
export type DeleteAdminSettingsMutationResult = Apollo.MutationResult<DeleteAdminSettingsMutation>;
export type DeleteAdminSettingsMutationOptions = Apollo.BaseMutationOptions<DeleteAdminSettingsMutation, DeleteAdminSettingsMutationVariables>;
export const GetAllArtworksDocument = gql`
    query GetAllArtworks($page: Int, $limit: Int, $lang: String) {
  getAllArtworks(page: $page, limit: $limit, lang: $lang) {
    artworks {
      id
      archiveId
      makerName
      maker {
        id
        text {
          name {
            default {
              text
            }
          }
        }
      }
      locationName
      location {
        id
        name
      }
      dateStarted
      meta
      media {
        urls {
          thumb
        }
      }
      text {
        ...TextContentFragment
      }
    }
    total
    hasMore
  }
}
    ${TextContentFragmentFragmentDoc}`;

/**
 * __useGetAllArtworksQuery__
 *
 * To run a query within a React component, call `useGetAllArtworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllArtworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllArtworksQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useGetAllArtworksQuery(baseOptions?: Apollo.QueryHookOptions<GetAllArtworksQuery, GetAllArtworksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllArtworksQuery, GetAllArtworksQueryVariables>(GetAllArtworksDocument, options);
      }
export function useGetAllArtworksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllArtworksQuery, GetAllArtworksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllArtworksQuery, GetAllArtworksQueryVariables>(GetAllArtworksDocument, options);
        }
export function useGetAllArtworksSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAllArtworksQuery, GetAllArtworksQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllArtworksQuery, GetAllArtworksQueryVariables>(GetAllArtworksDocument, options);
        }
export type GetAllArtworksQueryHookResult = ReturnType<typeof useGetAllArtworksQuery>;
export type GetAllArtworksLazyQueryHookResult = ReturnType<typeof useGetAllArtworksLazyQuery>;
export type GetAllArtworksSuspenseQueryHookResult = ReturnType<typeof useGetAllArtworksSuspenseQuery>;
export type GetAllArtworksQueryResult = Apollo.QueryResult<GetAllArtworksQuery, GetAllArtworksQueryVariables>;
export const GetArtworkByIdDocument = gql`
    query GetArtworkById($id: ID!, $lang: String) {
  getArtworkById(id: $id, lang: $lang) {
    id
    archiveId
    makerName
    maker {
      id
      text {
        name {
          default {
            text
          }
        }
      }
    }
    locationName
    location {
      id
      name
      city
      country
      type
    }
    dateStarted
    meta
    media {
      id
      urls {
        thumb
      }
      type
      order
      meta
    }
    text {
      ...TextContentFragment
    }
  }
}
    ${TextContentFragmentFragmentDoc}`;

/**
 * __useGetArtworkByIdQuery__
 *
 * To run a query within a React component, call `useGetArtworkByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtworkByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtworkByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useGetArtworkByIdQuery(baseOptions: Apollo.QueryHookOptions<GetArtworkByIdQuery, GetArtworkByIdQueryVariables> & ({ variables: GetArtworkByIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetArtworkByIdQuery, GetArtworkByIdQueryVariables>(GetArtworkByIdDocument, options);
      }
export function useGetArtworkByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetArtworkByIdQuery, GetArtworkByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetArtworkByIdQuery, GetArtworkByIdQueryVariables>(GetArtworkByIdDocument, options);
        }
export function useGetArtworkByIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetArtworkByIdQuery, GetArtworkByIdQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetArtworkByIdQuery, GetArtworkByIdQueryVariables>(GetArtworkByIdDocument, options);
        }
export type GetArtworkByIdQueryHookResult = ReturnType<typeof useGetArtworkByIdQuery>;
export type GetArtworkByIdLazyQueryHookResult = ReturnType<typeof useGetArtworkByIdLazyQuery>;
export type GetArtworkByIdSuspenseQueryHookResult = ReturnType<typeof useGetArtworkByIdSuspenseQuery>;
export type GetArtworkByIdQueryResult = Apollo.QueryResult<GetArtworkByIdQuery, GetArtworkByIdQueryVariables>;
export const CreateArtworkSimpleDocument = gql`
    mutation CreateArtworkSimple($input: CreateArtworkSimpleInput!) {
  createArtworkSimple(input: $input) {
    id
    archiveId
    makerName
    locationName
    dateStarted
    meta
    text {
      name {
        default {
          text
        }
      }
      description {
        default {
          text
        }
      }
    }
  }
}
    `;
export type CreateArtworkSimpleMutationFn = Apollo.MutationFunction<CreateArtworkSimpleMutation, CreateArtworkSimpleMutationVariables>;

/**
 * __useCreateArtworkSimpleMutation__
 *
 * To run a mutation, you first call `useCreateArtworkSimpleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArtworkSimpleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArtworkSimpleMutation, { data, loading, error }] = useCreateArtworkSimpleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateArtworkSimpleMutation(baseOptions?: Apollo.MutationHookOptions<CreateArtworkSimpleMutation, CreateArtworkSimpleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateArtworkSimpleMutation, CreateArtworkSimpleMutationVariables>(CreateArtworkSimpleDocument, options);
      }
export type CreateArtworkSimpleMutationHookResult = ReturnType<typeof useCreateArtworkSimpleMutation>;
export type CreateArtworkSimpleMutationResult = Apollo.MutationResult<CreateArtworkSimpleMutation>;
export type CreateArtworkSimpleMutationOptions = Apollo.BaseMutationOptions<CreateArtworkSimpleMutation, CreateArtworkSimpleMutationVariables>;
export const GetAllMakersAndLocationsDocument = gql`
    query GetAllMakersAndLocations($page: Int, $limit: Int, $lang: String) {
  getAllLocations(page: $page, limit: $limit) {
    locations {
      id
      name
      city
      country
      type
    }
    total
    hasMore
  }
  getAllMakers(page: $page, limit: $limit, lang: $lang) {
    makers {
      id
      birthPlace
      text {
        name {
          default {
            text
          }
        }
      }
    }
    total
    hasMore
  }
}
    `;

/**
 * __useGetAllMakersAndLocationsQuery__
 *
 * To run a query within a React component, call `useGetAllMakersAndLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllMakersAndLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllMakersAndLocationsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useGetAllMakersAndLocationsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllMakersAndLocationsQuery, GetAllMakersAndLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllMakersAndLocationsQuery, GetAllMakersAndLocationsQueryVariables>(GetAllMakersAndLocationsDocument, options);
      }
export function useGetAllMakersAndLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllMakersAndLocationsQuery, GetAllMakersAndLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllMakersAndLocationsQuery, GetAllMakersAndLocationsQueryVariables>(GetAllMakersAndLocationsDocument, options);
        }
export function useGetAllMakersAndLocationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAllMakersAndLocationsQuery, GetAllMakersAndLocationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllMakersAndLocationsQuery, GetAllMakersAndLocationsQueryVariables>(GetAllMakersAndLocationsDocument, options);
        }
export type GetAllMakersAndLocationsQueryHookResult = ReturnType<typeof useGetAllMakersAndLocationsQuery>;
export type GetAllMakersAndLocationsLazyQueryHookResult = ReturnType<typeof useGetAllMakersAndLocationsLazyQuery>;
export type GetAllMakersAndLocationsSuspenseQueryHookResult = ReturnType<typeof useGetAllMakersAndLocationsSuspenseQuery>;
export type GetAllMakersAndLocationsQueryResult = Apollo.QueryResult<GetAllMakersAndLocationsQuery, GetAllMakersAndLocationsQueryVariables>;
export const EditArtworkDocument = gql`
    mutation EditArtwork($id: ID!, $input: EditArtworkInput!) {
  editArtwork(id: $id, input: $input) {
    id
    archiveId
    makerName
    locationName
    dateStarted
    meta
    text {
      name {
        default {
          text
        }
      }
      description {
        default {
          text
        }
      }
    }
  }
}
    `;
export type EditArtworkMutationFn = Apollo.MutationFunction<EditArtworkMutation, EditArtworkMutationVariables>;

/**
 * __useEditArtworkMutation__
 *
 * To run a mutation, you first call `useEditArtworkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditArtworkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editArtworkMutation, { data, loading, error }] = useEditArtworkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditArtworkMutation(baseOptions?: Apollo.MutationHookOptions<EditArtworkMutation, EditArtworkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditArtworkMutation, EditArtworkMutationVariables>(EditArtworkDocument, options);
      }
export type EditArtworkMutationHookResult = ReturnType<typeof useEditArtworkMutation>;
export type EditArtworkMutationResult = Apollo.MutationResult<EditArtworkMutation>;
export type EditArtworkMutationOptions = Apollo.BaseMutationOptions<EditArtworkMutation, EditArtworkMutationVariables>;
export const DeleteArtworkDocument = gql`
    mutation DeleteArtwork($id: ID!) {
  deleteArtwork(id: $id)
}
    `;
export type DeleteArtworkMutationFn = Apollo.MutationFunction<DeleteArtworkMutation, DeleteArtworkMutationVariables>;

/**
 * __useDeleteArtworkMutation__
 *
 * To run a mutation, you first call `useDeleteArtworkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteArtworkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteArtworkMutation, { data, loading, error }] = useDeleteArtworkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteArtworkMutation(baseOptions?: Apollo.MutationHookOptions<DeleteArtworkMutation, DeleteArtworkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteArtworkMutation, DeleteArtworkMutationVariables>(DeleteArtworkDocument, options);
      }
export type DeleteArtworkMutationHookResult = ReturnType<typeof useDeleteArtworkMutation>;
export type DeleteArtworkMutationResult = Apollo.MutationResult<DeleteArtworkMutation>;
export type DeleteArtworkMutationOptions = Apollo.BaseMutationOptions<DeleteArtworkMutation, DeleteArtworkMutationVariables>;
export const GetAllLocationsDocument = gql`
    query getAllLocations($page: Int, $limit: Int) {
  getAllLocations(page: $page, limit: $limit) {
    locations {
      id
      name
      city
      country
      type
    }
    total
    hasMore
  }
}
    `;

/**
 * __useGetAllLocationsQuery__
 *
 * To run a query within a React component, call `useGetAllLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllLocationsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetAllLocationsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllLocationsQuery, GetAllLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllLocationsQuery, GetAllLocationsQueryVariables>(GetAllLocationsDocument, options);
      }
export function useGetAllLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllLocationsQuery, GetAllLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllLocationsQuery, GetAllLocationsQueryVariables>(GetAllLocationsDocument, options);
        }
export function useGetAllLocationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAllLocationsQuery, GetAllLocationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllLocationsQuery, GetAllLocationsQueryVariables>(GetAllLocationsDocument, options);
        }
export type GetAllLocationsQueryHookResult = ReturnType<typeof useGetAllLocationsQuery>;
export type GetAllLocationsLazyQueryHookResult = ReturnType<typeof useGetAllLocationsLazyQuery>;
export type GetAllLocationsSuspenseQueryHookResult = ReturnType<typeof useGetAllLocationsSuspenseQuery>;
export type GetAllLocationsQueryResult = Apollo.QueryResult<GetAllLocationsQuery, GetAllLocationsQueryVariables>;
export const CreateLocationDocument = gql`
    mutation createLocation($input: CreateLocationInput!) {
  createLocation(input: $input) {
    id
    name
    city
    country
    type
    createdAt
    updatedAt
  }
}
    `;
export type CreateLocationMutationFn = Apollo.MutationFunction<CreateLocationMutation, CreateLocationMutationVariables>;

/**
 * __useCreateLocationMutation__
 *
 * To run a mutation, you first call `useCreateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLocationMutation, { data, loading, error }] = useCreateLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLocationMutation(baseOptions?: Apollo.MutationHookOptions<CreateLocationMutation, CreateLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLocationMutation, CreateLocationMutationVariables>(CreateLocationDocument, options);
      }
export type CreateLocationMutationHookResult = ReturnType<typeof useCreateLocationMutation>;
export type CreateLocationMutationResult = Apollo.MutationResult<CreateLocationMutation>;
export type CreateLocationMutationOptions = Apollo.BaseMutationOptions<CreateLocationMutation, CreateLocationMutationVariables>;
export const DeleteLocationDocument = gql`
    mutation deleteLocation($id: ID!) {
  deleteLocation(id: $id)
}
    `;
export type DeleteLocationMutationFn = Apollo.MutationFunction<DeleteLocationMutation, DeleteLocationMutationVariables>;

/**
 * __useDeleteLocationMutation__
 *
 * To run a mutation, you first call `useDeleteLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLocationMutation, { data, loading, error }] = useDeleteLocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLocationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLocationMutation, DeleteLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLocationMutation, DeleteLocationMutationVariables>(DeleteLocationDocument, options);
      }
export type DeleteLocationMutationHookResult = ReturnType<typeof useDeleteLocationMutation>;
export type DeleteLocationMutationResult = Apollo.MutationResult<DeleteLocationMutation>;
export type DeleteLocationMutationOptions = Apollo.BaseMutationOptions<DeleteLocationMutation, DeleteLocationMutationVariables>;
export const GetAllMakersDocument = gql`
    query GetAllMakers($page: Int, $limit: Int, $lang: String) {
  getAllMakers(page: $page, limit: $limit, lang: $lang) {
    makers {
      id
      birthPlace
      dateOfBirth
      dateOfDeath
      text {
        name {
          default {
            text
          }
        }
        description {
          default {
            text
          }
        }
      }
    }
    total
    hasMore
  }
}
    `;

/**
 * __useGetAllMakersQuery__
 *
 * To run a query within a React component, call `useGetAllMakersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllMakersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllMakersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useGetAllMakersQuery(baseOptions?: Apollo.QueryHookOptions<GetAllMakersQuery, GetAllMakersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllMakersQuery, GetAllMakersQueryVariables>(GetAllMakersDocument, options);
      }
export function useGetAllMakersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllMakersQuery, GetAllMakersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllMakersQuery, GetAllMakersQueryVariables>(GetAllMakersDocument, options);
        }
export function useGetAllMakersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAllMakersQuery, GetAllMakersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllMakersQuery, GetAllMakersQueryVariables>(GetAllMakersDocument, options);
        }
export type GetAllMakersQueryHookResult = ReturnType<typeof useGetAllMakersQuery>;
export type GetAllMakersLazyQueryHookResult = ReturnType<typeof useGetAllMakersLazyQuery>;
export type GetAllMakersSuspenseQueryHookResult = ReturnType<typeof useGetAllMakersSuspenseQuery>;
export type GetAllMakersQueryResult = Apollo.QueryResult<GetAllMakersQuery, GetAllMakersQueryVariables>;
export const UpsertMakerDocument = gql`
    mutation UpsertMaker($input: UpsertMakerInput!) {
  upsertMaker(input: $input) {
    id
    user {
      id
    }
    dateOfBirth
    dateOfDeath
    birthPlace
    text {
      name {
        default {
          text
          audio {
            voiceName
            url
          }
        }
        short {
          text
          audio {
            voiceName
            url
          }
        }
        long {
          text
          audio {
            voiceName
            url
          }
        }
      }
      description {
        default {
          text
          audio {
            voiceName
            url
          }
        }
        short {
          text
          audio {
            voiceName
            url
          }
        }
        long {
          text
          audio {
            voiceName
            url
          }
        }
      }
    }
    meta
    createdAt
    updatedAt
  }
}
    `;
export type UpsertMakerMutationFn = Apollo.MutationFunction<UpsertMakerMutation, UpsertMakerMutationVariables>;

/**
 * __useUpsertMakerMutation__
 *
 * To run a mutation, you first call `useUpsertMakerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertMakerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertMakerMutation, { data, loading, error }] = useUpsertMakerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertMakerMutation(baseOptions?: Apollo.MutationHookOptions<UpsertMakerMutation, UpsertMakerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertMakerMutation, UpsertMakerMutationVariables>(UpsertMakerDocument, options);
      }
export type UpsertMakerMutationHookResult = ReturnType<typeof useUpsertMakerMutation>;
export type UpsertMakerMutationResult = Apollo.MutationResult<UpsertMakerMutation>;
export type UpsertMakerMutationOptions = Apollo.BaseMutationOptions<UpsertMakerMutation, UpsertMakerMutationVariables>;
export const DeleteMakerDocument = gql`
    mutation DeleteMaker($id: ID!) {
  deleteMaker(id: $id)
}
    `;
export type DeleteMakerMutationFn = Apollo.MutationFunction<DeleteMakerMutation, DeleteMakerMutationVariables>;

/**
 * __useDeleteMakerMutation__
 *
 * To run a mutation, you first call `useDeleteMakerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMakerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMakerMutation, { data, loading, error }] = useDeleteMakerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMakerMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMakerMutation, DeleteMakerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMakerMutation, DeleteMakerMutationVariables>(DeleteMakerDocument, options);
      }
export type DeleteMakerMutationHookResult = ReturnType<typeof useDeleteMakerMutation>;
export type DeleteMakerMutationResult = Apollo.MutationResult<DeleteMakerMutation>;
export type DeleteMakerMutationOptions = Apollo.BaseMutationOptions<DeleteMakerMutation, DeleteMakerMutationVariables>;
export const GenerateSignedUploadUrlDocument = gql`
    query GenerateSignedUploadUrl($input: GenerateSignedUploadUrlInput!) {
  generateSignedUploadUrl(input: $input) {
    signedUrl
    fileName
  }
}
    `;

/**
 * __useGenerateSignedUploadUrlQuery__
 *
 * To run a query within a React component, call `useGenerateSignedUploadUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateSignedUploadUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateSignedUploadUrlQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateSignedUploadUrlQuery(baseOptions: Apollo.QueryHookOptions<GenerateSignedUploadUrlQuery, GenerateSignedUploadUrlQueryVariables> & ({ variables: GenerateSignedUploadUrlQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GenerateSignedUploadUrlQuery, GenerateSignedUploadUrlQueryVariables>(GenerateSignedUploadUrlDocument, options);
      }
export function useGenerateSignedUploadUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GenerateSignedUploadUrlQuery, GenerateSignedUploadUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GenerateSignedUploadUrlQuery, GenerateSignedUploadUrlQueryVariables>(GenerateSignedUploadUrlDocument, options);
        }
export function useGenerateSignedUploadUrlSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GenerateSignedUploadUrlQuery, GenerateSignedUploadUrlQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GenerateSignedUploadUrlQuery, GenerateSignedUploadUrlQueryVariables>(GenerateSignedUploadUrlDocument, options);
        }
export type GenerateSignedUploadUrlQueryHookResult = ReturnType<typeof useGenerateSignedUploadUrlQuery>;
export type GenerateSignedUploadUrlLazyQueryHookResult = ReturnType<typeof useGenerateSignedUploadUrlLazyQuery>;
export type GenerateSignedUploadUrlSuspenseQueryHookResult = ReturnType<typeof useGenerateSignedUploadUrlSuspenseQuery>;
export type GenerateSignedUploadUrlQueryResult = Apollo.QueryResult<GenerateSignedUploadUrlQuery, GenerateSignedUploadUrlQueryVariables>;
export const ProcessUploadedMediaDocument = gql`
    mutation ProcessUploadedMedia($input: ProcessUploadedMediaInput!) {
  processUploadedMedia(input: $input) {
    id
    artwork {
      id
    }
    urls {
      thumb
    }
    type
    meta
  }
}
    `;
export type ProcessUploadedMediaMutationFn = Apollo.MutationFunction<ProcessUploadedMediaMutation, ProcessUploadedMediaMutationVariables>;

/**
 * __useProcessUploadedMediaMutation__
 *
 * To run a mutation, you first call `useProcessUploadedMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessUploadedMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processUploadedMediaMutation, { data, loading, error }] = useProcessUploadedMediaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProcessUploadedMediaMutation(baseOptions?: Apollo.MutationHookOptions<ProcessUploadedMediaMutation, ProcessUploadedMediaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProcessUploadedMediaMutation, ProcessUploadedMediaMutationVariables>(ProcessUploadedMediaDocument, options);
      }
export type ProcessUploadedMediaMutationHookResult = ReturnType<typeof useProcessUploadedMediaMutation>;
export type ProcessUploadedMediaMutationResult = Apollo.MutationResult<ProcessUploadedMediaMutation>;
export type ProcessUploadedMediaMutationOptions = Apollo.BaseMutationOptions<ProcessUploadedMediaMutation, ProcessUploadedMediaMutationVariables>;
export const DeleteMediaDocument = gql`
    mutation DeleteMedia($mediaId: ID!) {
  deleteMedia(mediaId: $mediaId)
}
    `;
export type DeleteMediaMutationFn = Apollo.MutationFunction<DeleteMediaMutation, DeleteMediaMutationVariables>;

/**
 * __useDeleteMediaMutation__
 *
 * To run a mutation, you first call `useDeleteMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMediaMutation, { data, loading, error }] = useDeleteMediaMutation({
 *   variables: {
 *      mediaId: // value for 'mediaId'
 *   },
 * });
 */
export function useDeleteMediaMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMediaMutation, DeleteMediaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMediaMutation, DeleteMediaMutationVariables>(DeleteMediaDocument, options);
      }
export type DeleteMediaMutationHookResult = ReturnType<typeof useDeleteMediaMutation>;
export type DeleteMediaMutationResult = Apollo.MutationResult<DeleteMediaMutation>;
export type DeleteMediaMutationOptions = Apollo.BaseMutationOptions<DeleteMediaMutation, DeleteMediaMutationVariables>;
export const PromoteMediaDocument = gql`
    mutation PromoteMedia($mediaId: ID!) {
  promoteMedia(mediaId: $mediaId)
}
    `;
export type PromoteMediaMutationFn = Apollo.MutationFunction<PromoteMediaMutation, PromoteMediaMutationVariables>;

/**
 * __usePromoteMediaMutation__
 *
 * To run a mutation, you first call `usePromoteMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePromoteMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [promoteMediaMutation, { data, loading, error }] = usePromoteMediaMutation({
 *   variables: {
 *      mediaId: // value for 'mediaId'
 *   },
 * });
 */
export function usePromoteMediaMutation(baseOptions?: Apollo.MutationHookOptions<PromoteMediaMutation, PromoteMediaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PromoteMediaMutation, PromoteMediaMutationVariables>(PromoteMediaDocument, options);
      }
export type PromoteMediaMutationHookResult = ReturnType<typeof usePromoteMediaMutation>;
export type PromoteMediaMutationResult = Apollo.MutationResult<PromoteMediaMutation>;
export type PromoteMediaMutationOptions = Apollo.BaseMutationOptions<PromoteMediaMutation, PromoteMediaMutationVariables>;
export const ExtractArtworkInfoFromImageDocument = gql`
    mutation ExtractArtworkInfoFromImage($input: ReadImageInfoInput!) {
  extractArtworkInfoFromImage(input: $input) {
    artworks {
      id
      archiveId
      artworkNames {
        text
        lang
      }
      artistNames {
        text
        lang
      }
      description {
        text
        lang
      }
      dateOfBirthOfArtist
      dateOfDeathOfArtist
      locationOfBirthOfArtist
      locationOfDeathOfArtist
      dateOfCreation
      material
      artworkLocation
    }
    success
    error
  }
}
    `;
export type ExtractArtworkInfoFromImageMutationFn = Apollo.MutationFunction<ExtractArtworkInfoFromImageMutation, ExtractArtworkInfoFromImageMutationVariables>;

/**
 * __useExtractArtworkInfoFromImageMutation__
 *
 * To run a mutation, you first call `useExtractArtworkInfoFromImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExtractArtworkInfoFromImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [extractArtworkInfoFromImageMutation, { data, loading, error }] = useExtractArtworkInfoFromImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExtractArtworkInfoFromImageMutation(baseOptions?: Apollo.MutationHookOptions<ExtractArtworkInfoFromImageMutation, ExtractArtworkInfoFromImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExtractArtworkInfoFromImageMutation, ExtractArtworkInfoFromImageMutationVariables>(ExtractArtworkInfoFromImageDocument, options);
      }
export type ExtractArtworkInfoFromImageMutationHookResult = ReturnType<typeof useExtractArtworkInfoFromImageMutation>;
export type ExtractArtworkInfoFromImageMutationResult = Apollo.MutationResult<ExtractArtworkInfoFromImageMutation>;
export type ExtractArtworkInfoFromImageMutationOptions = Apollo.BaseMutationOptions<ExtractArtworkInfoFromImageMutation, ExtractArtworkInfoFromImageMutationVariables>;
export const GetArtworksOrMakersDocument = gql`
    query GetArtworksOrMakers($input: GetArtworksOrMakersInput!) {
  getArtworksOrMakers(input: $input) {
    artworks {
      schema
      name
      description
      ttsDescription
      artistName
      movement
      dateStarted
      location
      medium
      technique
      materials
      relations {
        people
        location
        theme
        eventDepicted
        iconography
        controversy
        socioPolitical
        historical
        culturalSensitive
        politicalStatement
        religiousTheme
        symbolism
      }
    }
    makers {
      schema
      name
      description
      ttsDescription
      movements
      dateOfBirth
      dateOfDeath
      birthPlace
    }
  }
}
    `;

/**
 * __useGetArtworksOrMakersQuery__
 *
 * To run a query within a React component, call `useGetArtworksOrMakersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtworksOrMakersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtworksOrMakersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetArtworksOrMakersQuery(baseOptions: Apollo.QueryHookOptions<GetArtworksOrMakersQuery, GetArtworksOrMakersQueryVariables> & ({ variables: GetArtworksOrMakersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetArtworksOrMakersQuery, GetArtworksOrMakersQueryVariables>(GetArtworksOrMakersDocument, options);
      }
export function useGetArtworksOrMakersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetArtworksOrMakersQuery, GetArtworksOrMakersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetArtworksOrMakersQuery, GetArtworksOrMakersQueryVariables>(GetArtworksOrMakersDocument, options);
        }
export function useGetArtworksOrMakersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetArtworksOrMakersQuery, GetArtworksOrMakersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetArtworksOrMakersQuery, GetArtworksOrMakersQueryVariables>(GetArtworksOrMakersDocument, options);
        }
export type GetArtworksOrMakersQueryHookResult = ReturnType<typeof useGetArtworksOrMakersQuery>;
export type GetArtworksOrMakersLazyQueryHookResult = ReturnType<typeof useGetArtworksOrMakersLazyQuery>;
export type GetArtworksOrMakersSuspenseQueryHookResult = ReturnType<typeof useGetArtworksOrMakersSuspenseQuery>;
export type GetArtworksOrMakersQueryResult = Apollo.QueryResult<GetArtworksOrMakersQuery, GetArtworksOrMakersQueryVariables>;
export const TranslateTextDocument = gql`
    query TranslateText($input: TranslateTextInput!) {
  translateText(input: $input) {
    text
    ttsDescription
    schema
  }
}
    `;

/**
 * __useTranslateTextQuery__
 *
 * To run a query within a React component, call `useTranslateTextQuery` and pass it any options that fit your needs.
 * When your component renders, `useTranslateTextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTranslateTextQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTranslateTextQuery(baseOptions: Apollo.QueryHookOptions<TranslateTextQuery, TranslateTextQueryVariables> & ({ variables: TranslateTextQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TranslateTextQuery, TranslateTextQueryVariables>(TranslateTextDocument, options);
      }
export function useTranslateTextLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TranslateTextQuery, TranslateTextQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TranslateTextQuery, TranslateTextQueryVariables>(TranslateTextDocument, options);
        }
export function useTranslateTextSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TranslateTextQuery, TranslateTextQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TranslateTextQuery, TranslateTextQueryVariables>(TranslateTextDocument, options);
        }
export type TranslateTextQueryHookResult = ReturnType<typeof useTranslateTextQuery>;
export type TranslateTextLazyQueryHookResult = ReturnType<typeof useTranslateTextLazyQuery>;
export type TranslateTextSuspenseQueryHookResult = ReturnType<typeof useTranslateTextSuspenseQuery>;
export type TranslateTextQueryResult = Apollo.QueryResult<TranslateTextQuery, TranslateTextQueryVariables>;
export const SynthesizeVoiceDocument = gql`
    mutation SynthesizeVoice($input: SynthesizeVoiceInput!) {
  synthesizeVoice(input: $input) {
    url
  }
}
    `;
export type SynthesizeVoiceMutationFn = Apollo.MutationFunction<SynthesizeVoiceMutation, SynthesizeVoiceMutationVariables>;

/**
 * __useSynthesizeVoiceMutation__
 *
 * To run a mutation, you first call `useSynthesizeVoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSynthesizeVoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [synthesizeVoiceMutation, { data, loading, error }] = useSynthesizeVoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSynthesizeVoiceMutation(baseOptions?: Apollo.MutationHookOptions<SynthesizeVoiceMutation, SynthesizeVoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SynthesizeVoiceMutation, SynthesizeVoiceMutationVariables>(SynthesizeVoiceDocument, options);
      }
export type SynthesizeVoiceMutationHookResult = ReturnType<typeof useSynthesizeVoiceMutation>;
export type SynthesizeVoiceMutationResult = Apollo.MutationResult<SynthesizeVoiceMutation>;
export type SynthesizeVoiceMutationOptions = Apollo.BaseMutationOptions<SynthesizeVoiceMutation, SynthesizeVoiceMutationVariables>;
export const LoginDocument = gql`
    mutation Login($token: String!, $source: String!, $id: String) {
  login(token: $token, source: $source, id: $id) {
    id
    name
    email
    avatar
    role
    jwt
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      token: // value for 'token'
 *      source: // value for 'source'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;