import React, { useState } from 'react';
import { Alert, Button, Container, Form, Modal, Pagination, Spinner, Table } from 'react-bootstrap';

import { useDeleteLocationMutation, useGetAllLocationsQuery } from '../../graphql/server-graphql-schema';
import CreateLocationPage from './CreateLocationPage';

const LocationListPage: React.FC = () => {
  const [showCreateLocationModal, setShowCreateLocationModal] = useState(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [locationToDelete, setLocationToDelete] = useState<string | null>(null);
  const [page, setPage] = useState(1);
  const [limit] = useState(10); // Set the limit per page
  const [searchQuery, setSearchQuery] = useState('');

  const { data, loading, error, refetch } = useGetAllLocationsQuery({
    variables: { page, limit },
  });

  const [deleteLocationMutation] = useDeleteLocationMutation();

  const handleAddLocation = () => {
    setShowCreateLocationModal(true);
  };

  const handleCreateLocationModalClose = () => {
    setShowCreateLocationModal(false);
  };

  const handleCreateLocationModalSuccess = async () => {
    setShowCreateLocationModal(false);
    await refetch();
  };

  const handleDeleteLocation = (locationId: string) => {
    setLocationToDelete(locationId);
    setShowDeleteConfirmModal(true);
  };

  const confirmDeleteLocation = async () => {
    if (locationToDelete) {
      try {
        await deleteLocationMutation({ variables: { id: locationToDelete } });
        setShowDeleteConfirmModal(false);
        setLocationToDelete(null);
        await refetch();
      } catch (err) {
        console.error('Error deleting location:', err);
        alert('Failed to delete location. Please try again.');
      }
    }
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const filteredLocations = data?.getAllLocations?.locations.filter((location) =>
    location.name.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  if (loading) {
    return (
      <Container className="text-center mt-5">
        <Spinner animation="border" role="status" />
        <span className="sr-only">Loading...</span>
      </Container>
    );
  }

  if (error) {
    return (
      <Container className="mt-5">
        <Alert variant="danger">Error: {error.message}</Alert>
      </Container>
    );
  }

  return (
    <Container className="mt-5">
      <div className="d-flex justify-content-between mb-4">
        <h2>Location List</h2>
        <Button variant="primary" onClick={handleAddLocation}>
          Add New
        </Button>
      </div>

      <Form className="mb-3">
        <Form.Control
          type="text"
          placeholder="Search locations..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </Form>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Name</th>
            <th>City</th>
            <th>Country</th>
            <th>Type</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {filteredLocations?.length ? (
            filteredLocations.map((location) => (
              <tr key={location.id}>
                <td>{location.name}</td>
                <td>{location.city || 'N/A'}</td>
                <td>{location.country}</td>
                <td>{location.type}</td>
                <td>
                  <Button variant="outline-danger" size="sm" onClick={() => handleDeleteLocation(location.id)}>
                    Delete
                  </Button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={5} className="text-center">
                No locations found.
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      <div className="d-flex justify-content-center mt-4">
        <Pagination>
          <Pagination.Prev onClick={() => handlePageChange(page - 1)} disabled={page <= 1} />
          {[...Array(data?.getAllLocations?.total ? Math.ceil(data.getAllLocations.total / limit) : 0)].map((_, index) => (
            <Pagination.Item key={index + 1} active={index + 1 === page} onClick={() => handlePageChange(index + 1)}>
              {index + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next onClick={() => handlePageChange(page + 1)} disabled={data?.getAllLocations?.hasMore === false} />
        </Pagination>
      </div>

      {/* Modal for creating new location */}
      <Modal show={showCreateLocationModal} onHide={handleCreateLocationModalClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Create New Location</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreateLocationPage onClose={handleCreateLocationModalClose} onSuccess={handleCreateLocationModalSuccess} />
        </Modal.Body>
      </Modal>

      {/* Modal for confirming delete action */}
      <Modal show={showDeleteConfirmModal} onHide={() => setShowDeleteConfirmModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this location? This action cannot be undone.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteConfirmModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={confirmDeleteLocation}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default LocationListPage;
