import React, { useEffect, useState } from 'react';
import { Button, Card, Container, Form } from 'react-bootstrap';

import { useCreateLocationMutation } from '../../graphql/server-graphql-schema';

type CreateLocationPageProps = {
  onClose: () => void;
  onSuccess: (newMakerId: string, name: string) => void;
  initialName?: string; // Initial name to pre-fill in the form
};

const CreateLocationPage: React.FC<CreateLocationPageProps> = ({ onClose, onSuccess, initialName = '' }) => {
  const [name, setName] = useState(initialName);
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [type, setType] = useState('');

  const [createLocationMutation] = useCreateLocationMutation();

  useEffect(() => {
    if (initialName) {
      setName(initialName);
    }
  }, [initialName]);

  const handleCreateLocation = async () => {
    try {
      const response = await createLocationMutation({
        variables: {
          input: {
            name,
            city: city || null,
            country,
            type,
          },
        },
      });
      if (response.data?.createLocation) {
        onSuccess(response.data.createLocation.id, response.data.createLocation.name);
      } else {
        throw new Error('Failed to retrieve Location ID after save');
      }

      onClose();
    } catch (error) {
      console.error('Error creating location:', error);
    }
  };

  return (
    <Container className="mt-5">
      <h2>Create New Location</h2>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          void handleCreateLocation();
        }}
      >
        <Card className="mb-4 p-3">
          <Card.Title>Location Information</Card.Title>

          <Form.Group controlId="formLocationName">
            <Form.Label>Name</Form.Label>
            <Form.Control type="text" value={name} onChange={(e) => setName(e.target.value)} required />
          </Form.Group>

          <Form.Group controlId="formCity" className="mt-2">
            <Form.Label>City</Form.Label>
            <Form.Control type="text" value={city} onChange={(e) => setCity(e.target.value)} />
          </Form.Group>

          <Form.Group controlId="formCountry" className="mt-2">
            <Form.Label>Country</Form.Label>
            <Form.Control type="text" value={country} onChange={(e) => setCountry(e.target.value)} required />
          </Form.Group>

          <Form.Group controlId="formLocationType" className="mt-2">
            <Form.Label>Type</Form.Label>
            <Form.Select value={type} onChange={(e) => setType(e.target.value)} required>
              <option value="">Select a type...</option>
              <option value="MUSEUM">Museum</option>
              <option value="GALLERY">Gallery</option>
              <option value="EXHIBITION">Exhibition</option>
              <option value="OTHER">Other</option>
            </Form.Select>
          </Form.Group>
        </Card>

        <div className="d-flex justify-content-between">
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="primary" type="submit">
            Create Location
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default CreateLocationPage;
