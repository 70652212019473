import { Route, Routes } from 'react-router-dom';

import AdminSettingsManagementPage from '../AdminSettings/AdminSettingsManagementPage';
import ArtworkDetailsPage from '../artwork/ArtworkDetailsPage';
import ArtworkListPage from '../artwork/ArtworkListPage';
import CreateArtworkSimplePage from '../artwork/CreateArtworkSimplePage';
import EditArtworkPage from '../artwork/EditArtworkPage';
import HomePage from '../HomePage';
import LocationListPage from '../location/LocationListPage';
import LoginPage from '../LoginPage';
import MakerListPage from '../maker/MakerListPage';
import SearchPage from '../mobile/SearchPage';
import PrivacyPage from '../PrivacyPage';
import SettingsPage from '../SettingsPage';
import TOUPage from '../TOUPage';

const PublicRoutes = () => (
  <Routes>
    <Route path="/appsettings" element={<AdminSettingsManagementPage />} />
    <Route path="/settings" element={<SettingsPage />} />

    <Route path="/artworks" element={<ArtworkListPage />} />
    <Route path="/artworks/:id" element={<ArtworkDetailsPage />} />
    <Route path="/edit-artwork/:id" element={<EditArtworkPage />} />
    <Route path="/create-artwork" element={<CreateArtworkSimplePage />} />

    <Route path="/makers" element={<MakerListPage />} />
    <Route path="/locations" element={<LocationListPage />} />

    <Route path="/mobile/search" element={<SearchPage />} />

    <Route path="/login" element={<LoginPage />} />
    <Route path="/privacy" element={<PrivacyPage />} />
    <Route path="/tou" element={<TOUPage />} />
    <Route path="/" element={<HomePage />} />
    <Route path="" element={<HomePage />} />
    <Route path="*" element={<p>Path not resolved</p>} />
  </Routes>
);

export default PublicRoutes;
