import React from 'react';
import { Alert, Col, Container, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';

import { useGetArtworkByIdQuery } from '../../graphql/server-graphql-schema';
import { renderImageThumbs } from './render-util';

const ArtworkDetailsPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const { data, loading, error } = useGetArtworkByIdQuery({
    variables: { id: id || '', lang: 'en' },
    skip: !id,
  });

  if (loading) {
    return (
      <Container className="mt-5">
        <p>Loading...</p>
      </Container>
    );
  }

  if (error) {
    return (
      <Container className="mt-5">
        <Alert variant="danger">Error loading artwork: {error.message}</Alert>
      </Container>
    );
  }

  const artwork = data?.getArtworkById;

  if (!artwork) {
    return (
      <Container className="mt-5">
        <Alert variant="warning">Artwork not found.</Alert>
      </Container>
    );
  }

  const nameText = artwork.text?.name?.default?.text || 'N/A';
  const descriptionText = artwork.text?.description?.default?.text || 'N/A';
  const makerName = artwork.makerName || 'Unknown';
  const material = artwork.meta?.material || 'N/A';
  const dateStarted = artwork.dateStarted || 'N/A';

  return (
    <Container className="mt-5">
      <Row>
        <Col>
          <h2>{nameText}</h2>
          <p>
            <strong>Maker:</strong> {makerName}
          </p>
          <p>
            <strong>Material:</strong> {material}
          </p>
          <p>
            <strong>Date Started:</strong> {dateStarted}
          </p>
          <p>
            <strong>Artist Info:</strong> Born {artwork.meta?.dateOfBirthOfArtist} in {artwork.meta?.locationOfBirthOfArtist},
            Died {artwork.meta?.dateOfDeathOfArtist} in {artwork.meta?.locationOfDeathOfArtist}
          </p>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <h4>Description</h4>
          <p>{descriptionText}</p>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <h4>Media</h4>
          <div style={styles.mediaContainer}>{renderImageThumbs(artwork)}</div>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <Link to="/artworks">Back to Artwork List</Link>
        </Col>
      </Row>
    </Container>
  );
};

export default ArtworkDetailsPage;

const styles = {
  mediaContainer: {
    display: 'flex',
    flexWrap: 'wrap' as const,
    gap: '20px',
  },
  mediaItem: {
    flex: '0 1 150px',
    textAlign: 'center' as const,
  },
  mediaImage: {
    maxWidth: '150px',
    maxHeight: '150px',
    borderRadius: '8px',
    objectFit: 'cover' as const,
  },
};
