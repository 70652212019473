import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';

import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CompDownloadAndPlayVoice from '../../components/DownloadAndPlayVoice';
import LanguageSelector from '../../components/LanguageSelector';
import { OaiArtwork } from '../../graphql/server-graphql-schema';

interface ArtworkProps {
  data: OaiArtwork;
}

const ArtworkRenderer: React.FC<ArtworkProps> = ({ data: artwork }) => {
  const [translatedDescription, setTranslatedDescription] = useState<string | null>(artwork.description || null);
  const [translatedTtsDescription, setTranslatedTtsDescription] = useState<string | null>(artwork.ttsDescription || null);
  const [selectedLanguage, setSelectedLanguage] = useState<string>('en-US');

  const handleLanguageUpdate = (lang: string, description: string | null, ttsDescription: string | null) => {
    setTranslatedDescription(description);
    setTranslatedTtsDescription(ttsDescription);
    setSelectedLanguage(lang);
  };

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <span style={styles.title}>{artwork.name}</span>
        <LanguageSelector
          text={artwork.description || ''}
          ttsDescription={artwork.ttsDescription || ''}
          onUpdate={handleLanguageUpdate}
        />
        {artwork.ttsDescription && artwork.description && (
          <div style={styles.buttonContainer}>
            <CompDownloadAndPlayVoice
              id={translatedTtsDescription || ''}
              text={translatedDescription || ''}
              lang={selectedLanguage}
            />
          </div>
        )}
      </div>

      <div style={styles.body}>
        <div style={styles.description}>
          {translatedDescription ? <ReactMarkdown>{translatedDescription}</ReactMarkdown> : <p>No description available.</p>}
        </div>
        <strong>
          {artwork.artistName || 'Unknown'}, {artwork.dateStarted || 'Unknown'}
        </strong>
        <div style={styles.location}>
          <FontAwesomeIcon icon={faLocationDot} style={styles.icon} /> {artwork.location || 'Unknown'}
        </div>
        {(artwork.medium || artwork.materials) && (
          <div style={styles.additionalInfo}>
            {artwork.medium && <span>{artwork.medium}</span>}
            {artwork.materials && <span> | {artwork.materials}</span>}
          </div>
        )}
        {artwork.relations && (
          <div style={styles.relations}>
            {artwork.relations.people && (
              <p>
                <strong>People:</strong> {artwork.relations.people.join(', ')}
              </p>
            )}
            {artwork.relations.location && (
              <p>
                <strong>Location:</strong> {artwork.relations.location}
              </p>
            )}
            {artwork.relations.theme && (
              <p>
                <strong>Theme:</strong> {artwork.relations.theme}
              </p>
            )}
            {artwork.relations.eventDepicted && (
              <p>
                <strong>Event:</strong> {artwork.relations.eventDepicted}
              </p>
            )}
            {artwork.relations.iconography && (
              <p>
                <strong>Iconography:</strong> {artwork.relations.iconography}
              </p>
            )}
            {artwork.relations.controversy && (
              <p>
                <strong>Controversy:</strong> {artwork.relations.controversy}
              </p>
            )}
            {artwork.relations.socioPolitical && (
              <p>
                <strong>Socio-Political:</strong> {artwork.relations.socioPolitical}
              </p>
            )}
            {artwork.relations.historical && (
              <p>
                <strong>Historical:</strong> {artwork.relations.historical}
              </p>
            )}
            {artwork.relations.culturalSensitive && (
              <p>
                <strong>Cultural Sensitivity:</strong> {artwork.relations.culturalSensitive}
              </p>
            )}
            {artwork.relations.politicalStatement && (
              <p>
                <strong>Political Statement:</strong> {artwork.relations.politicalStatement}
              </p>
            )}
            {artwork.relations.religiousTheme && (
              <p>
                <strong>Religious Theme:</strong> {artwork.relations.religiousTheme}
              </p>
            )}
            {artwork.relations.symbolism && (
              <p>
                <strong>Symbolism:</strong> {artwork.relations.symbolism}
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    border: '1px solid #ccc',
    borderRadius: '8px',
    padding: '16px',
    marginBottom: '16px',
  },
  icon: {
    marginRight: '8px',
  },
  body: {
    marginTop: '8px',
  },
  description: {
    marginTop: '8px',
  },
  location: {
    marginTop: '8px',
    fontStyle: 'italic',
  },
  additionalInfo: {
    marginTop: '8px',
  },
  relations: {
    marginTop: '16px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: '35px',
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    flex: '1',
  },
  buttonContainer: {
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'flex-end',
  },
};

export default ArtworkRenderer;
