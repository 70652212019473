import React, { useState } from 'react';
import { NavDropdown } from 'react-bootstrap';
import Container from 'react-bootstrap/esm/Container';
import Navbar from 'react-bootstrap/esm/Navbar';
import Image from 'react-bootstrap/Image';
import Nav from 'react-bootstrap/Nav';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { faUser } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { RootState } from '../store/store';

const NavBarComponent: React.FC = () => {
  const auth = useSelector((state: RootState) => state.auth.auth);
  const [isExpanded, setIsExpanded] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleLinkClick = (path: string) => {
    setIsExpanded(false);
    if (location.pathname !== path) {
      navigate(path);
    }
  };

  return (
    <Navbar collapseOnSelect expand="lg" className="py-3 bg-body-tertiary navbar-custom" expanded={isExpanded}>
      <Container>
        {/* Logo */}
        <Navbar.Brand as={Link} to="/" className="d-flex align-items-center">
          <Image
            src={`${process.env.PUBLIC_URL}/images/logo128.png`}
            alt="Art Hista"
            width="auto"
            height="80"
            className="d-none d-md-inline-block align-middle"
          />
          <Image
            src={`${process.env.PUBLIC_URL}/images/logo64.png`}
            alt="Art Hista"
            width="auto"
            height="64"
            className="d-inline-block d-md-none align-middle"
          />

          {/* Site Name */}
          <div className="d-flex align-items-center mx-auto">
            <h1 className="display-6 fw-bold lh-1 ms-3 mb-0 mt-md-0 text-center" style={{ marginRight: 'auto' }}>
              Art
              <span className="fst-italic" style={{ color: '#6c757d', opacity: 0.7 }}>
                Hista
              </span>
            </h1>
          </div>
        </Navbar.Brand>

        {/* Navbar Toggle for Mobile */}
        <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setIsExpanded(!isExpanded)} />

        <Navbar.Collapse id="responsive-navbar-nav">
          {/* Navigation Links */}
          <Nav className="ms-auto mt-3 mt-md-0 nav-links">
            <Nav.Link as={Link} onClick={() => handleLinkClick('/')} to="/">
              Home
            </Nav.Link>
            <Nav.Link as={Link} onClick={() => handleLinkClick('/mobile/search')} to="/mobile/search">
              Mobile
            </Nav.Link>
            <Nav.Link as={Link} onClick={() => handleLinkClick('/artworks')} to="/artworks">
              Artworks
            </Nav.Link>
            {auth.role === 'ADMIN' && (
              <Nav.Link as={Link} to="/appsettings">
                Admin
              </Nav.Link>
            )}
          </Nav>

          {/* User Section */}
          <Nav className="ms-auto mt-3 mt-md-0 nav-links">
            {auth.email === '' ? (
              <Nav.Link as={Link} to="/login" onClick={() => handleLinkClick('/login')} className="text-decoration-none">
                Login
              </Nav.Link>
            ) : (
              <NavDropdown
                title={
                  <>
                    <FontAwesomeIcon icon={faUser} className="me-1" style={{ color: 'gray' }} />
                    Account
                  </>
                }
                id="collasible-nav-dropdown"
              >
                <NavDropdown.Item as={Link} to="/settings" onClick={() => handleLinkClick('/settings')}>
                  Settings
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/archive" onClick={() => handleLinkClick('/archive')}>
                  History
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} onClick={() => handleLinkClick('/makers')} to="/makers">
                  Makers
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} onClick={() => handleLinkClick('/locations')} to="/locations">
                  Locations
                </NavDropdown.Item>
              </NavDropdown>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBarComponent;
