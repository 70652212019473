import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import { faSpinner, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { MAX_IMAGE_SIZE } from '../constants';
import { useExtractArtworkInfoFromImageMutation, useGenerateSignedUploadUrlLazyQuery } from '../graphql/server-graphql-schema';
import { setError, updateArtwork } from '../store/coreSlice';
import { RootState } from '../store/store';
import { resizeImage } from '../utility/image-util';
import { handleFileUpload } from '../utility/uploadFile-util';

// type Artwork = ExtractArtworkInfoFromImageMutation['extractArtworkInfoFromImage']['artworks'][number]['m'];

type CompDragDropInfocardUploadProps = {
  resetTrigger?: boolean;
};

const CompDragDropInfocardUpload: React.FC<CompDragDropInfocardUploadProps> = ({ resetTrigger }) => {
  const [uploading, setUploading] = useState(false);

  const error = useSelector((state: RootState) => state.core.error);

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const dispatch = useDispatch();

  const [extractArtworkInfoFromImage] = useExtractArtworkInfoFromImageMutation();
  const [generateSignedUploadUrl] = useGenerateSignedUploadUrlLazyQuery({ fetchPolicy: 'network-only' });

  useEffect(() => {
    if (resetTrigger) {
      resetState();
    }
  }, [resetTrigger]);

  const resetState = () => {
    setUploading(false);
    setError(null);
  };

  const afterFileUpload = async (fileName: string) => {
    try {
      const extractResult = await extractArtworkInfoFromImage({
        variables: { input: { imagePath: fileName } },
      });

      if (
        extractResult.data?.extractArtworkInfoFromImage.success &&
        extractResult.data.extractArtworkInfoFromImage.artworks.length > 0
      ) {
        const artworkInfo = extractResult.data.extractArtworkInfoFromImage.artworks[0];

        dispatch(updateArtwork(artworkInfo));
      } else {
        dispatch(setError(extractResult.data?.extractArtworkInfoFromImage.error || 'No text found'));
      }
    } catch (err) {
      console.error(err);
      dispatch(setError('Failed to upload file or extract artwork info'));
    } finally {
      setUploading(false);
    }
  };

  const onDrop = async (acceptedFiles: File[]) => {
    dispatch(setError(null));

    if (acceptedFiles.length === 0) {
      dispatch(setError('No files selected'));
      return;
    }

    try {
      setUploading(true);

      for (const file of acceptedFiles) {
        let fileToUpload: File | Blob = file;

        if (file.type.startsWith('image/') && file.size > MAX_IMAGE_SIZE) {
          fileToUpload = await resizeImage(file);
        }

        await handleFileUpload(fileToUpload, generateSignedUploadUrl, afterFileUpload);
      }
    } catch (uploadError) {
      console.error(uploadError);
      dispatch(setError('Failed to upload file'));
      setUploading(false);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { 'image/*': [] },
    maxFiles: 5,
  });

  return (
    <div>
      {!isMobile ? (
        <div className="ms-5">
          <div style={styles.wrapper}>
            <img src={`${process.env.PUBLIC_URL}/images/dnd-infocard-s.png`} style={styles.image} title="Upload" />
            <div {...getRootProps()} style={styles.uploadArea}>
              <input {...getInputProps()} />
            </div>
          </div>
        </div>
      ) : (
        <div className="text-center mt-4">
          <Button variant="primary" style={{ minWidth: 140 }} {...getRootProps()}>
            <FontAwesomeIcon icon={faUpload} className="me-2" /> Upload Object label
          </Button>
          <input {...getInputProps()} style={{ display: 'none' }} />
        </div>
      )}

      <div className="mt-2">
        {uploading && (
          <div className="text-center">
            <FontAwesomeIcon icon={faSpinner} spin /> Uploading...
          </div>
        )}

        {error && <div className="text-danger text-center">{error}</div>}
      </div>
    </div>
  );
};

export default CompDragDropInfocardUpload;

const styles = {
  uploadArea: {
    position: 'absolute' as 'absolute',
    bottom: 0,
    height: '33%',
    width: '100%',
    background: 'rgba(255, 255, 255, 0.2)',
    border: '2px dashed #cccccc',
    borderRadius: '0 0 8px 8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 10,
    cursor: 'pointer',
  },
  icon: {
    fontSize: '24px',
    color: '#007bff',
    cursor: 'pointer',
  },
  wrapper: {
    position: 'relative' as 'relative',
    width: '100%',
    maxWidth: '250px',
    margin: '0 auto',
  },
  image: {
    width: '100%',
    objectFit: 'cover' as 'cover',
  },
};
