import { LazyQueryExecFunction } from '@apollo/client';

import { Exact, GenerateSignedUploadUrlInput, GenerateSignedUploadUrlQuery } from '../graphql/server-graphql-schema';

export const uploadToSignedUrl = async (url: string, file: File | Blob) => {
  await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': file.type || 'application/octet-stream',
    },
    body: file,
  });
};

export const handleFileUpload = async (
  file: File | Blob,
  generateSignedUploadUrl: LazyQueryExecFunction<
    GenerateSignedUploadUrlQuery,
    Exact<{
      input: GenerateSignedUploadUrlInput;
    }>
  >,
  nextAction: (fileName: string) => Promise<void>,
) => {
  try {
    // Generate signed upload URL
    const { data } = await generateSignedUploadUrl({
      variables: {
        input: {
          contentType: file.type || 'application/octet-stream',
        },
      },
    });

    if (data?.generateSignedUploadUrl) {
      const { signedUrl, fileName } = data.generateSignedUploadUrl;

      // Upload the file to Google Cloud Storage
      await uploadToSignedUrl(signedUrl, file);

      // Call the callback function
      await nextAction(fileName);
    } else {
      throw new Error('Failed to generate signed upload URL');
    }
  } catch (err) {
    console.error('Error during file upload:', err);
  }
};
