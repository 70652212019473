import { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import CompCreateArtworkSimpleForm from '../../components/CompCreateArtworkSimpleForm';
import CompDragDropImageUpload from '../../components/CompDragDropImageUpload';
import CompDragDropInfocardUpload from '../../components/CompDragDropInfocardUpload';
import { resetArtwork, resetMedia } from '../../store/coreSlice';

const CreateArtworkSimplePage: React.FC = () => {
  const [error, setError] = useState<string | null>(null);

  const dispatch = useDispatch();

  useEffect(() => {
    resetState();
  }, [dispatch]);

  const resetState = () => {
    setError(null);
    dispatch(resetArtwork());
    dispatch(resetMedia());
  };
  return (
    <Container className="mt-5">
      <h2>Create or Edit Artwork</h2>

      <div className="d-flex justify-content-center align-items-center mb-3">
        <Button variant="secondary" onClick={resetState}>
          Reset
        </Button>
      </div>

      <div className="text-center text-danger">{error}</div>

      <Row>
        <Col md={6}>
          <CompDragDropInfocardUpload />
        </Col>
        <Col md={6}>
          <CompDragDropImageUpload />
        </Col>
      </Row>
      <CompCreateArtworkSimpleForm resetTrigger={false} />
    </Container>
  );
};

export default CreateArtworkSimplePage;
