import { PayloadAction } from '@reduxjs/toolkit';

import { Media } from '../graphql/server-graphql-schema';
import { CoreState } from './coreSlice';

export const ACTION_MEDIA = {
  addMedia: (state: CoreState, action: PayloadAction<Media>) => {
    state.medias.push(action.payload);
  },
  updateMedia: (state: CoreState, action: PayloadAction<Partial<Media> & { id: string }>) => {
    const mediaIndex = state.medias.findIndex((media) => media.id === action.payload.id);
    if (mediaIndex !== -1) {
      state.medias[mediaIndex] = { ...state.medias[mediaIndex], ...action.payload };
    }
  },
  deleteMedia: (state: CoreState, action: PayloadAction<string>) => {
    state.medias = state.medias.filter((media) => media.id !== action.payload);
  },
  resetMedia: (state: CoreState) => {
    state.medias = [];
  },
};
