import React, { useState } from 'react';
import { Alert, Button, Container, Form, Modal, Pagination, Spinner, Table } from 'react-bootstrap';

import { useDeleteMakerMutation, useGetAllMakersQuery } from '../../graphql/server-graphql-schema';
import CreateMakerPage from './CreateMakerPage';

const MakerListPage: React.FC = () => {
  const [showCreateMakerModal, setShowCreateMakerModal] = useState(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [makerToDelete, setMakerToDelete] = useState<string | null>(null);
  const [page, setPage] = useState(1);
  const [limit] = useState(20); // Set the limit per page
  const [searchQuery, setSearchQuery] = useState('');

  const { data, loading, error, refetch } = useGetAllMakersQuery({
    variables: { page, limit, lang: 'en' },
  });

  const [deleteMakerMutation] = useDeleteMakerMutation();

  const handleAddMaker = () => {
    setShowCreateMakerModal(true);
  };

  const handleCreateMakerModalClose = () => {
    setShowCreateMakerModal(false);
  };

  const handleCreateMakerModalSuccess = async () => {
    setShowCreateMakerModal(false);
    await refetch();
  };

  const handleDeleteMaker = (makerId: string) => {
    setMakerToDelete(makerId);
    setShowDeleteConfirmModal(true);
  };

  const confirmDeleteMaker = async () => {
    if (makerToDelete) {
      try {
        await deleteMakerMutation({ variables: { id: makerToDelete } });
        setShowDeleteConfirmModal(false);
        setMakerToDelete(null);
        await refetch();
      } catch (err) {
        console.error('Error deleting maker:', err);
        alert('Failed to delete maker. Please try again.');
      }
    }
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const filteredMakers = data?.getAllMakers?.makers.filter((maker) =>
    maker.text?.name?.default?.text.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  if (loading) {
    return (
      <Container className="text-center mt-5">
        <Spinner animation="border" role="status" />
        <span className="sr-only">Loading...</span>
      </Container>
    );
  }

  if (error) {
    return (
      <Container className="mt-5">
        <Alert variant="danger">Error: {error.message}</Alert>
      </Container>
    );
  }

  return (
    <Container className="mt-5">
      <div className="d-flex justify-content-between mb-4">
        <h2>Maker List</h2>
        <Button variant="primary" onClick={handleAddMaker}>
          Add New
        </Button>
      </div>

      <Form className="mb-3">
        <Form.Control
          type="text"
          placeholder="Search makers..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </Form>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Name</th>
            <th>Birth Place</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {filteredMakers?.length ? (
            filteredMakers.map((maker) => (
              <tr key={maker.id}>
                <td>{maker.text?.name?.default?.text}</td>
                <td>{maker.birthPlace || 'N/A'}</td>
                <td>
                  <Button variant="outline-danger" size="sm" onClick={() => handleDeleteMaker(maker.id)}>
                    Delete
                  </Button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={3} className="text-center">
                No makers found.
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      <div className="d-flex justify-content-center mt-4">
        <Pagination>
          <Pagination.Prev onClick={() => handlePageChange(page - 1)} disabled={page <= 1} />
          {[...Array(data?.getAllMakers?.total ? Math.ceil(data.getAllMakers.total / limit) : 0)].map((_, index) => (
            <Pagination.Item key={index + 1} active={index + 1 === page} onClick={() => handlePageChange(index + 1)}>
              {index + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next onClick={() => handlePageChange(page + 1)} disabled={data?.getAllMakers?.hasMore === false} />
        </Pagination>
      </div>

      {/* Modal for creating new maker */}
      <Modal show={showCreateMakerModal} onHide={handleCreateMakerModalClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Create New Maker</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreateMakerPage onClose={handleCreateMakerModalClose} onSuccess={handleCreateMakerModalSuccess} />
        </Modal.Body>
      </Modal>

      {/* Modal for confirming delete action */}
      <Modal show={showDeleteConfirmModal} onHide={() => setShowDeleteConfirmModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this maker? This action cannot be undone.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteConfirmModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={confirmDeleteMaker}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default MakerListPage;
