import { CONTENT_BASE_URL } from '../../constants';
import { GetAllArtworksQuery } from '../../graphql/server-graphql-schema';

type Artwork = GetAllArtworksQuery['getAllArtworks']['artworks'][number];

export const renderImageThumbs = (artwork: Artwork) => {
  const nameText = artwork?.text?.name?.default?.text || 'Untitled';

  return (
    <div>
      {artwork?.media?.map(
        (mediaItem, index) =>
          mediaItem?.urls?.thumb && (
            <img
              key={index}
              src={CONTENT_BASE_URL + mediaItem.urls.thumb}
              alt={`image: ${nameText}`}
              className="artwork-image-preview"
            />
          ),
      )}
    </div>
  );
};
