import React, { useEffect } from 'react';
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { CreateArtworkSimpleInput, OcrLocalizedText, useCreateArtworkSimpleMutation } from '../graphql/server-graphql-schema';
import { resetArtwork, resetMedia, setError, updateArtwork } from '../store/coreSlice';
import { RootState } from '../store/store';

type CompCreateArtworkSimpleFormProps = {
  resetTrigger?: boolean;
};

const CompCreateArtworkSimpleForm: React.FC<CompCreateArtworkSimpleFormProps> = ({ resetTrigger }) => {
  const error = useSelector((state: RootState) => state.core.error);
  const ocrArtwork = useSelector((state: RootState) => state.core.ocrArtwork);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [createArtworkSimple] = useCreateArtworkSimpleMutation();

  useEffect(() => {
    if (resetTrigger) {
      resetState();
    }
  }, [dispatch]); // Reset state when resetTrigger changes

  const resetState = () => {
    dispatch(setError(null));
    dispatch(resetArtwork());
    dispatch(resetMedia());
  };

  const handleFieldChange = (field: string, value: string) => {
    dispatch(updateArtwork({ [field]: value }));
  };

  const handleUploadArtwork = async () => {
    if (!ocrArtwork) {
      return;
    }

    try {
      // Prepare localizedText array
      const localizedText = (ocrArtwork.artworkNames || []).map((artworkName) => ({
        lang: artworkName.lang,
        name: artworkName.text,
        description: ocrArtwork.description?.find((desc) => desc.lang === artworkName.lang)?.text || '',
        makerName: ocrArtwork.artistNames?.find((artist) => artist.lang === artworkName.lang)?.text || '',
        locationName: ocrArtwork.artworkLocation || '',
      }));

      const input: CreateArtworkSimpleInput = {
        id: ocrArtwork.id,
        dateStarted: ocrArtwork.dateOfCreation || '',
        archiveId: ocrArtwork.archiveId,
        meta: {
          material: ocrArtwork.material,
          dateOfBirthOfArtist: ocrArtwork.dateOfBirthOfArtist,
          dateOfDeathOfArtist: ocrArtwork.dateOfDeathOfArtist,
          locationOfBirthOfArtist: ocrArtwork.locationOfBirthOfArtist,
          locationOfDeathOfArtist: ocrArtwork.locationOfDeathOfArtist,
        },
        text: localizedText,
      };

      // Upsert the artwork
      await createArtworkSimple({
        variables: {
          input,
        },
      });

      // Navigate to the artworks list page after success
      navigate('/artworks');
    } catch (error) {
      console.error('Error uploading artwork:', error);
      setError('Failed to upload artwork.');
    }
  };

  const renderInputField = (
    label: string,
    value: string,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    clearField: () => void,
  ) => (
    <Form.Group className="mb-3">
      <Form.Label>{label}</Form.Label>
      <InputGroup>
        <Form.Control type="text" value={value} onChange={onChange} />
        <InputGroup.Text onClick={clearField} style={styles.clearButton}>
          <FontAwesomeIcon icon={faTimes} />
        </InputGroup.Text>
      </InputGroup>
    </Form.Group>
  );

  return (
    <div>
      {ocrArtwork && (
        <Row>
          <Col md={6}>
            <h3 className="mb-4">Artwork and Artist Names</h3>
            <Form>
              {ocrArtwork.artworkNames?.map((nameObj: OcrLocalizedText, index: number) => (
                <React.Fragment key={`artworkName-${index}`}>
                  {renderInputField(
                    `Artwork Name (${nameObj.lang})`,
                    nameObj.text || '',
                    (e) => handleFieldChange(`artworkNames[${index}].name`, e.target.value),
                    () => handleFieldChange(`artworkNames[${index}].name`, ''),
                  )}
                </React.Fragment>
              ))}
              {ocrArtwork.artistNames?.map((artistObj: OcrLocalizedText, index: number) => (
                <React.Fragment key={`artistName-${index}`}>
                  {renderInputField(
                    `Artist Name (${artistObj.lang})`,
                    artistObj.text || '',
                    (e) => handleFieldChange(`artistNames[${index}].name`, e.target.value),
                    () => handleFieldChange(`artistNames[${index}].name`, ''),
                  )}
                </React.Fragment>
              ))}
              {ocrArtwork.description?.map((descObj: OcrLocalizedText, index: number) => (
                <React.Fragment key={`description-${index}`}>
                  {renderInputField(
                    `Description (${descObj.lang})`,
                    descObj.text || '',
                    (e) => handleFieldChange(`description[${index}].text`, e.target.value),
                    () => handleFieldChange(`description[${index}].text`, ''),
                  )}
                </React.Fragment>
              ))}
            </Form>

            {error && <div style={styles.errorText}>{error}</div>}

            <div style={{ textAlign: 'center', marginTop: '50px' }}>
              <Button variant="primary" onClick={handleUploadArtwork}>
                Upload Artwork
              </Button>
            </div>
          </Col>
          <Col md={6}>
            <h3 className="mb-4">Other Information</h3>
            <Form>
              {[
                'archiveId',
                'dateOfCreation',
                'material',
                'dateOfBirthOfArtist',
                'dateOfDeathOfArtist',
                'locationOfBirthOfArtist',
                'locationOfDeathOfArtist',
                'artworkLocation',
              ].map((key, index) => {
                const value = ocrArtwork[key as keyof typeof ocrArtwork];
                if (typeof value === 'string') {
                  return (
                    <React.Fragment key={`infoField-${index}`}>
                      {renderInputField(
                        key,
                        value,
                        (e) => handleFieldChange(key, e.target.value),
                        () => handleFieldChange(key, ''),
                      )}
                    </React.Fragment>
                  );
                }
                return null;
              })}
            </Form>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default CompCreateArtworkSimpleForm;

const styles = {
  wrapper: {
    position: 'relative' as 'relative',
    width: '100%',
    maxWidth: '250px',
    margin: '0 auto',
  },
  image: {
    width: '100%',
    objectFit: 'cover' as 'cover',
  },
  uploadArea: {
    position: 'absolute' as 'absolute',
    bottom: 0,
    height: '33%',
    width: '100%',
    background: 'rgba(255, 255, 255, 0.8)',
    border: '2px dashed #cccccc',
    borderRadius: '0 0 8px 8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 10,
    cursor: 'pointer',
  },
  errorText: {
    color: 'red',
    marginTop: '10px',
  },
  clearButton: {
    cursor: 'pointer',
  },
  icon: {
    fontSize: '24px',
  },
};
