import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import GoogleOAuth from '../components/GoogleOAuth';
import { upgradeStore } from '../store/coreSlice';
import { RootState } from '../store/store';

const LoginPage: React.FC<any> = () => {
  const location = useLocation();
  const redirectTo = location.state?.from?.pathname || '/';

  const dispatch = useDispatch();
  const auth = useSelector((state: RootState) => state.auth.auth);

  useEffect(() => {
    dispatch(upgradeStore());
  }, [auth]); // Empty dependency array means this effect runs once after initial render.

  return (
    <div className="container mt-4 mb-5">
      <Helmet>
        <title>Login</title>
      </Helmet>
      <div className="text-center">
        <GoogleOAuth redirectTo={redirectTo} />
      </div>
    </div>
  );
};

export default LoginPage;
