import { PayloadAction } from '@reduxjs/toolkit';

import { OcrArtwork } from '../graphql/server-graphql-schema';
import { CoreState } from './coreSlice';

export const ACTION_ARTWORK = {
  setArtwork: (state: CoreState, action: PayloadAction<OcrArtwork | null>) => {
    state.ocrArtwork = action.payload;
  },
  updateArtwork: (state: CoreState, action: PayloadAction<Partial<OcrArtwork>>) => {
    if (state.ocrArtwork) {
      state.ocrArtwork = {
        ...state.ocrArtwork,
        ...action.payload,
        id: state.ocrArtwork.id || action.payload.id, // Preserve existing ID if it exists
      };
    } else {
      state.ocrArtwork = action.payload;
    }
  },
  resetArtwork: (state: CoreState) => {
    state.ocrArtwork = null;
  },
};
