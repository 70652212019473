import React, { useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';

import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type CompSearchBarProps = {
  onSearch: (text: string) => void;
  loading: boolean;
};

const CompSearchBar: React.FC<CompSearchBarProps> = ({ onSearch, loading }) => {
  const [searchValue, setSearchValue] = useState<string>('');

  const handleSearch = (text: string) => {
    if (!text) return;

    onSearch(text);
  };

  const handleKeyUp = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSearch(searchValue);
    }
  };

  return (
    <div className="d-flex align-items-center w-100" style={{ height: '100%' }}>
      <Form.Control
        type="text"
        placeholder="Type to search..."
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        onKeyUp={handleKeyUp}
        className="me-2"
      />
      <Button
        variant="primary"
        onClick={() => handleSearch(searchValue)} // Fix: Pass a reference to a function
        disabled={loading || !searchValue}
      >
        {loading ? <Spinner animation="border" size="sm" /> : <FontAwesomeIcon icon={faSearch} />}
      </Button>
    </div>
  );
};

export default CompSearchBar;

const styles = {};
