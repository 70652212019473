import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';

import { useMutation } from '@apollo/client';
import { faSpinner, faVolumeUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { SynthesizeVoiceDocument } from '../graphql/server-graphql-schema';

interface CompDownloadAndPlayVoiceProps {
  id: string;
  text: string;
  lang: string;
}

const CompDownloadAndPlayVoice: React.FC<CompDownloadAndPlayVoiceProps> = ({ id, text, lang }) => {
  const [audioUrl, setAudioUrl] = useState<string | null>(null); // State for storing the audio URL
  const [synthesizeVoice, { loading, error }] = useMutation(SynthesizeVoiceDocument, {
    onCompleted: (data) => {
      if (data.synthesizeVoice.url) {
        setAudioUrl(data.synthesizeVoice.url);
      }
    },
    onError: (err) => {
      setAudioUrl(null);
      console.error('SynthesizeVoice error:', err);
    },
  });

  // Reset audioUrl when the id changes
  useEffect(() => {
    setAudioUrl(null);
  }, [id]);

  const handleSynthesizeVoice = async () => {
    setAudioUrl(null);

    void synthesizeVoice({
      variables: {
        input: {
          id,
          lang,
          text,
          override: false,
        },
      },
    });
  };

  return (
    <div>
      {!audioUrl && (
        <Button variant="secondary" onClick={handleSynthesizeVoice} disabled={loading}>
          {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : <FontAwesomeIcon icon={faVolumeUp} />}
        </Button>
      )}
      {audioUrl && (
        <div>
          <audio controls src={audioUrl}>
            Your browser does not support the audio element.
          </audio>
        </div>
      )}
      {error && <p style={{ color: 'red' }}>Failed to synthesize voice.</p>}
    </div>
  );
};

export default CompDownloadAndPlayVoice;
