import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Media, OcrArtwork } from '../graphql/server-graphql-schema';
import { ACTION_ARTWORK } from './actionsArtwork';
import { ACTION_MEDIA } from './actionsMedia';
import { ACTION_SETTINGS } from './actionsSettings';

export interface CoreState {
  ocrArtwork: Partial<OcrArtwork> | null;
  medias: Partial<Media>[];
  error: string | null;

  settings: {
    defaultTransLang: string;
    transLangs: string[];
    lang: string;
  };
}

export const initialState: CoreState = {
  ocrArtwork: null,
  medias: [],
  error: null,

  settings: {
    defaultTransLang: 'en-US',
    transLangs: ['en-US'],
    lang: 'es-MX',
  },
};

const sentenceSlice = createSlice({
  name: 'core',
  initialState,
  reducers: {
    upgradeStore: (state: CoreState) => {},
    ...ACTION_SETTINGS,
    ...ACTION_ARTWORK,
    ...ACTION_MEDIA,

    setError: (state: CoreState, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
  },
});

export const {
  upgradeStore,
  updateSettings,
  setArtwork,
  updateArtwork,
  resetArtwork,
  addMedia,
  updateMedia,
  deleteMedia,
  resetMedia,
  setError,
} = sentenceSlice.actions;

export default sentenceSlice.reducer;
