import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';

import CompDownloadAndPlayVoice from '../../components/DownloadAndPlayVoice';
import LanguageSelector from '../../components/LanguageSelector';
import { OaiMaker } from '../../graphql/server-graphql-schema';

interface MakerProps {
  data: OaiMaker;
}

const MakerRenderer: React.FC<MakerProps> = ({ data: maker }) => {
  const [translatedDescription, setTranslatedDescription] = useState<string | null>(maker.description || null);
  const [translatedTtsDescription, setTranslatedTtsDescription] = useState<string | null>(maker.ttsDescription || null);
  const [selectedLanguage, setSelectedLanguage] = useState<string>('en-US');

  const handleUpdate = (lang: string, description: string | null, ttsDescription: string | null) => {
    setTranslatedDescription(description);
    setTranslatedTtsDescription(ttsDescription);
    setSelectedLanguage(lang);
  };

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <div style={styles.title}>{maker.name}</div>
        <LanguageSelector text={maker.description || ''} ttsDescription={maker.ttsDescription || ''} onUpdate={handleUpdate} />
        {maker.ttsDescription && maker.description && (
          <div style={styles.buttonContainer}>
            <CompDownloadAndPlayVoice
              id={translatedTtsDescription || ''}
              text={translatedDescription || ''}
              lang={selectedLanguage}
            />
          </div>
        )}
      </div>

      <div style={styles.body}>
        <div style={styles.description}>
          <ReactMarkdown>{translatedDescription || ''}</ReactMarkdown>
        </div>
        <div style={styles.date}>
          <strong>
            {maker.dateOfBirth || 'Unknown'} - {maker.dateOfDeath || 'Unknown'}
          </strong>
          <span style={styles.birthPlace}>{maker.birthPlace || ''}</span>
        </div>
        {maker.movements && (
          <div style={styles.movements}>
            <strong>Movements:</strong> {maker.movements.join(', ')}
          </div>
        )}
      </div>
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '35px',
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    flex: '1',
  },
  buttonContainer: {
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  container: {
    border: '1px solid #ccc',
    borderRadius: '8px',
    padding: '16px',
    marginBottom: '16px',
  },
  body: {
    marginTop: '8px',
  },
  description: {
    marginTop: '8px',
  },
  date: {
    marginTop: '8px',
  },
  birthPlace: {
    marginLeft: '16px',
    fontStyle: 'italic',
  },
  movements: {
    marginTop: '16px',
  },
};

export default MakerRenderer;
