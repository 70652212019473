import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import { faSpinner, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { CONTENT_BASE_URL } from '../constants';
import {
    MediaType, useGenerateSignedUploadUrlLazyQuery, useProcessUploadedMediaMutation
} from '../graphql/server-graphql-schema';
import { addMedia, setError, updateArtwork } from '../store/coreSlice';
import { RootState } from '../store/store';
import { resizeImage } from '../utility/image-util';
import { handleFileUpload } from '../utility/uploadFile-util';

const MAX_IMAGE_SIZE_MB = 1; // Maximum image size in MB before resizing

type CompDragDropImageUploadProps = {};

const CompDragDropImageUpload: React.FC<CompDragDropImageUploadProps> = ({}) => {
  const [uploading, setUploading] = useState(false);
  const [uploadedMediaUrls, setUploadedMediaUrls] = useState<string[]>([]);

  const error = useSelector((state: RootState) => state.core.error);

  const [processUploadedMedia] = useProcessUploadedMediaMutation();
  const [generateSignedUploadUrl] = useGenerateSignedUploadUrlLazyQuery({ fetchPolicy: 'network-only' });
  const ocrArtwork = useSelector((state: RootState) => state.core.ocrArtwork);

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const dispatch = useDispatch();

  const handleProcessUploadedMedia = async (fileName: string) => {
    const result = await processUploadedMedia({
      variables: {
        input: {
          artworkId: ocrArtwork?.id,
          fileName,
          type: MediaType.Image,
        },
      },
    });

    if (result.data?.processUploadedMedia) {
      const media = result.data.processUploadedMedia;
      const mediaUrl = media.urls?.thumb;

      if (mediaUrl) {
        setUploadedMediaUrls((prevUrls) => [...prevUrls, CONTENT_BASE_URL + mediaUrl]);

        // Write the media to the Redux store
        dispatch(addMedia(media));

        if (media.artwork) {
          dispatch(updateArtwork({ id: media.artwork.id }));
        }
      }
    } else {
      throw new Error('Failed to process uploaded media');
    }
  };

  const afterFileUpload = async (fileName: string) => {
    try {
      await handleProcessUploadedMedia(fileName);
      dispatch(setError(null));
    } catch (err) {
      console.error(err);
      dispatch(setError('Failed to upload file'));
    } finally {
      setUploading(false);
    }
  };

  const onDrop = async (acceptedFiles: File[]) => {
    dispatch(setError(null));

    if (acceptedFiles.length === 0) {
      dispatch(setError('No files selected'));
      return;
    }

    try {
      setUploading(true);
      for (const file of acceptedFiles) {
        let fileToUpload: File | Blob = file;

        if (file.type.startsWith('image/') && file.size > MAX_IMAGE_SIZE_MB * 1024 * 1024) {
          fileToUpload = await resizeImage(file);
        }

        await handleFileUpload(fileToUpload, generateSignedUploadUrl, afterFileUpload);
      }
    } catch (uploadError) {
      console.error(uploadError);
      dispatch(setError('Failed to upload file(s)'));
      setUploading(false);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { 'image/*': [] },
    maxFiles: 10,
  });

  return (
    <>
      {!isMobile ? (
        <div className="ms-5">
          <div style={styles.wrapper}>
            <img src={`${process.env.PUBLIC_URL}/images/dnd-image-s.png`} style={styles.image} title="Upload" />
            <div {...getRootProps()} style={styles.uploadArea}>
              <input {...getInputProps()} />
            </div>
          </div>
        </div>
      ) : (
        <div className="text-center mt-4">
          <Button variant="primary" style={{ minWidth: 140 }} {...getRootProps()}>
            <FontAwesomeIcon icon={faUpload} className="me-2" /> Upload Image
          </Button>
          <input {...getInputProps()} style={{ display: 'none' }} />
        </div>
      )}

      <div className="mt-2">
        {uploading && (
          <div className="text-center">
            <FontAwesomeIcon icon={faSpinner} spin /> Uploading...
          </div>
        )}

        {error && <div className="text-danger text-center">{error}</div>}
      </div>

      {uploadedMediaUrls.length > 0 && (
        <div style={styles.previewContainer}>
          <h5>Uploaded Images</h5>
          <div style={styles.mediaGrid}>
            {uploadedMediaUrls.map((url, index) => (
              <img key={index} src={url} alt={`Uploaded ${index + 1}`} style={styles.previewImage} />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default CompDragDropImageUpload;

const styles = {
  wrapper: {
    position: 'relative' as 'relative',
    width: '100%',
    maxWidth: '250px',
    margin: '0 auto',
  },
  image: {
    width: '100%',
    objectFit: 'cover' as 'cover',
  },
  uploadArea: {
    position: 'absolute' as 'absolute',
    bottom: 0,
    height: '33%',
    width: '100%',
    background: 'rgba(255, 255, 255, 0.2)',
    border: '2px dashed #cccccc',
    borderRadius: '0 0 8px 8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 10,
    cursor: 'pointer',
  },
  previewContainer: {
    marginTop: '20px',
  },
  mediaGrid: {
    display: 'flex',
    flexWrap: 'wrap' as const,
    gap: '10px',
    justifyContent: 'center',
  },
  previewImage: {
    maxWidth: '150px',
    maxHeight: '150px',
    borderRadius: '8px',
    objectFit: 'cover' as const,
  },
  icon: {
    fontSize: '24px',
  },
};
