import React, { useState } from 'react';
import { Alert, Button, Col, Container, ListGroup, Row, Spinner } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { faEdit, faEye, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useDeleteArtworkMutation, useGetAllArtworksQuery } from '../../graphql/server-graphql-schema';
import { RootState } from '../../store/store';
import { renderImageThumbs } from './render-util';

const ArtworkListPage: React.FC = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const auth = useSelector((state: RootState) => state.auth.auth);

  const { data, loading, error, refetch } = useGetAllArtworksQuery({
    variables: {
      page: currentPage,
      limit: itemsPerPage,
      lang: 'en',
    },
    fetchPolicy: 'network-only',
  });

  const [deleteArtwork] = useDeleteArtworkMutation();
  const [visibleArtworkId, setVisibleArtworkId] = useState<string | null>(null);
  const [deletingArtworkId, setDeletingArtworkId] = useState<string | null>(null);

  const toggleVisibility = (artworkId: string) => {
    setVisibleArtworkId((prev) => (prev === artworkId ? null : artworkId));
  };

  const handleDelete = async (artworkId: string) => {
    if (window.confirm('Are you sure you want to delete this artwork?')) {
      try {
        setDeletingArtworkId(artworkId);
        await deleteArtwork({
          variables: { id: artworkId },
        });
        await refetch();
      } catch (error) {
        console.error('Error deleting artwork:', error);
        alert('Failed to delete artwork. Please try again.');
      } finally {
        setDeletingArtworkId(null);
      }
    }
  };

  const handlePageChange = ({ selected }: { selected: number }) => {
    setCurrentPage(selected + 1);
  };

  if (loading) {
    return (
      <Container className="text-center mt-5">
        <Spinner animation="border" role="status" />
        <span className="sr-only">Loading...</span>
      </Container>
    );
  }

  if (error) {
    return (
      <Container className="mt-5">
        <Alert variant="danger">Error: {error.message}</Alert>
      </Container>
    );
  }

  const totalPages = Math.ceil((data?.getAllArtworks?.total || 0) / itemsPerPage);

  return (
    <Container className="mt-5">
      <Row className="mb-4">
        <Col>
          <h2>Artwork List</h2>
        </Col>
        <Col className="text-end">
          {auth.role === 'ADMIN' && (
            <Button variant="outline-primary" onClick={() => navigate('/create-artwork')}>
              Add Artwork
            </Button>
          )}
        </Col>
      </Row>
      <ListGroup>
        {data?.getAllArtworks?.artworks.map((artwork) => {
          const localeContent = artwork.text;
          const nameText = localeContent?.name?.default?.text || 'No name available';
          const descriptionText = localeContent?.description?.default?.text;
          const makerName = artwork.maker?.text?.name?.default?.text || artwork.makerName;
          const locationName = artwork.location?.name || artwork.locationName;

          return (
            <ListGroup.Item key={artwork.id} style={styles.artworkItem}>
              <Row>
                <Col sm={6}>
                  <strong>
                    <Link to={`/artworks/${artwork.id}`}>{nameText}</Link>
                  </strong>
                  <span className="ms-2">({artwork.dateStarted})</span>
                  <div>{makerName}</div>
                  <div>{locationName}</div>
                  <div className="text-muted mt-2">
                    <small>{descriptionText}</small>
                  </div>
                </Col>

                <Col sm={6} style={styles.actionContainer}>
                  {auth.role === 'ADMIN' && (
                    <div className="action-buttons" style={styles.actionButtons}>
                      <Button
                        className="me-2"
                        variant="outline-primary"
                        size="sm"
                        onClick={() => navigate(`/edit-artwork/${artwork.id}`)}
                        title="Edit Artwork"
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </Button>

                      <Button
                        className="me-2"
                        variant="outline-primary"
                        size="sm"
                        onClick={() => toggleVisibility(artwork.id)}
                        title={visibleArtworkId === artwork.id ? 'Hide Details' : 'Show Details'}
                      >
                        <FontAwesomeIcon icon={faEye} />
                      </Button>

                      <Button
                        className="me-2"
                        variant="outline-danger"
                        size="sm"
                        onClick={() => handleDelete(artwork.id)}
                        disabled={deletingArtworkId === artwork.id}
                        title="Delete Artwork"
                      >
                        {deletingArtworkId === artwork.id ? (
                          <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                        ) : (
                          <FontAwesomeIcon icon={faTrash} />
                        )}
                      </Button>
                    </div>
                  )}
                  {renderImageThumbs(artwork)}
                </Col>
              </Row>
              {visibleArtworkId === artwork.id && <pre style={{ marginTop: '10px' }}>{JSON.stringify(artwork, null, 2)}</pre>}
            </ListGroup.Item>
          );
        })}
      </ListGroup>
      <Row className="mt-4">
        <Col className="text-center">
          <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            breakLabel={'...'}
            pageCount={totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageChange}
            containerClassName={'pagination'}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}
            previousClassName={'page-item'}
            previousLinkClassName={'page-link'}
            nextClassName={'page-item'}
            nextLinkClassName={'page-link'}
            breakClassName={'page-item'}
            breakLinkClassName={'page-link'}
            activeClassName={'active'}
          />
        </Col>
      </Row>
    </Container>
  );
};

const styles = {
  artworkItem: {
    padding: '20px',
    borderBottom: '1px solid #eaeaea',
    position: 'relative' as const,
  },
  artworkImagePreview: {
    width: '100px',
    height: '100px',
    objectFit: 'contain', // Ensures image fits within bounds without cropping
    backgroundColor: 'white', // Adds padding around images
    padding: '10px',
    border: '1px solid #ddd',
    borderRadius: '4px',
  },
  actionContainer: {
    position: 'relative' as const,
    textAlign: 'right' as const,
  },
  actionButtons: {
    display: 'flex',
    gap: '5px',
  },
};

export default ArtworkListPage;
